/* HTML: <div class="loader"></div> */
.loader {
        width: 120px;
        height: 24px;
        display:block;
        -webkit-mask:
          radial-gradient(circle closest-side,#000 94%,#0000) 0 0/25% 100%,
          linear-gradient(#000 0 0) center/calc(100% - 12px) calc(100% - 12px) no-repeat;
        background:
         linear-gradient(#25b09b 0 0) 0/0% no-repeat
         #ddd;
        animation: l7 2s infinite linear;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      @keyframes l7 {
          100% {background-size:100%}
      }