/* Buttons */
/* Inputs */
/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #4A89DC;
    --bs-secondary: #656D78;
    --bs-success: #37BC9B;
    --bs-info: #3BAFDA;
    --bs-warning: #F6BB42;
    --bs-danger: #E9573F;
    --bs-light: #E6E9ED;
    --bs-dark: #434A54;
    --bs-font-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box; }
  
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth; } }
  
  body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #434A54;
    background-color: #F5F7FA;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important; }
  
  hr {
    margin: 2rem 0;
    color: #adb5bd;
    background-color: currentColor;
    border: 0;
    opacity: 0.25; }
  
  hr:not([size]) {
    height: 1px; }
  
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    line-height: 1.2; }
  
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw); }
    @media (min-width: 1200px) {
      h1, .h1 {
        font-size: 2.5rem; } }
  
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw); }
    @media (min-width: 1200px) {
      h2, .h2 {
        font-size: 2rem; } }
  
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw); }
    @media (min-width: 1200px) {
      h3, .h3 {
        font-size: 1.75rem; } }
  
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw); }
    @media (min-width: 1200px) {
      h4, .h4 {
        font-size: 1.5rem; } }
  
  h5, .h5 {
    font-size: 1.25rem; }
  
  h6, .h6 {
    font-size: 1rem; }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem; }
  
  abbr[title],
  abbr[data-bs-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none; }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  
  ol,
  ul {
    padding-left: 2rem; }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0; }
  
  dt {
    font-weight: 700; }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  
  blockquote {
    margin: 0 0 1rem; }
  
  b,
  strong {
    font-weight: bolder; }
  
  small, .small {
    font-size: 0.875em; }
  
  mark, .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  
  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline; }
  
  sub {
    bottom: -.25em; }
  
  sup {
    top: -.5em; }
  
  a {
    color: #4A89DC;
    text-decoration: underline; }
    a:hover {
      color: #3b6eb0; }
  
  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }
  
  pre,
  code,
  kbd,
  samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr /* rtl:ignore */;
    unicode-bidi: bidi-override; }
  
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em; }
    pre code {
      font-size: inherit;
      color: inherit;
      word-break: normal; }
  
  code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word; }
    a > code {
      color: inherit; }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem; }
    kbd kbd {
      padding: 0;
      font-size: 1em;
      font-weight: 700; }
  
  figure {
    margin: 0 0 1rem; }
  
  img,
  svg {
    vertical-align: middle; }
  
  table {
    caption-side: bottom;
    border-collapse: collapse; }
  
  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left; }
  
  th {
    text-align: inherit;
    text-align: -webkit-match-parent; }
  
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0; }
  
  label {
    display: inline-block; }
  
  button {
    border-radius: 0; }
  
  button:focus {
    outline: dotted 1px;
    outline: -webkit-focus-ring-color auto 5px; }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  
  button,
  select {
    text-transform: none; }
  
  [role="button"] {
    cursor: pointer; }
  
  select {
    word-wrap: normal; }
  
  [list]::-webkit-calendar-picker-indicator {
    display: none; }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; }
    button:not(:disabled),
    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled) {
      cursor: pointer; }
  
  ::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  
  textarea {
    resize: vertical; }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit; }
    @media (min-width: 1200px) {
      legend {
        font-size: 1.5rem; } }
    legend + * {
      clear: left; }
  
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0; }
  
  ::-webkit-inner-spin-button {
    height: auto; }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield; }
  
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  ::-webkit-search-decoration {
    -webkit-appearance: none; }
  
  ::-webkit-color-swatch-wrapper {
    padding: 0; }
  
  ::file-selector-button {
    font: inherit; }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  
  output {
    display: inline-block; }
  
  iframe {
    border: 0; }
  
  summary {
    display: list-item;
    cursor: pointer; }
  
  progress {
    vertical-align: baseline; }
  
  [hidden] {
    display: none !important; }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  
  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .display-1 {
        font-size: 5rem; } }
  
  .display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .display-2 {
        font-size: 4.5rem; } }
  
  .display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .display-3 {
        font-size: 4rem; } }
  
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .display-4 {
        font-size: 3.5rem; } }
  
  .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .display-5 {
        font-size: 3rem; } }
  
  .display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2; }
    @media (min-width: 1200px) {
      .display-6 {
        font-size: 2.5rem; } }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none; }
  
  .list-inline {
    padding-left: 0;
    list-style: none; }
  
  .list-inline-item {
    display: inline-block; }
    .list-inline-item:not(:last-child) {
      margin-right: 0.5rem; }
  
  .initialism {
    font-size: 0.875em;
    text-transform: uppercase; }
  
  .blockquote {
    margin-bottom: 2rem;
    font-size: 1.25rem; }
    .blockquote > :last-child {
      margin-bottom: 0; }
  
  .blockquote-footer {
    margin-top: -2rem;
    margin-bottom: 2rem;
    font-size: 0.875em;
    color: #6c757d; }
    .blockquote-footer::before {
      content: "\2014\00A0"; }
  
  .img-fluid {
    max-width: 100%;
    height: auto; }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #F5F7FA;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto; }
  
  .figure {
    display: inline-block; }
  
  .figure-img {
    margin-bottom: 1rem;
    line-height: 1; }
  
  .figure-caption {
    font-size: 0.875em;
    color: #6c757d; }
  
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto; }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px; } }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px; } }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px; } }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1060px; } }
  
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2); }
    .row > * {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
      margin-top: var(--bs-gutter-y); }
  
  .col {
    flex: 1 0 0%; }
  
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto; }
  
  .col-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  
  .col-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  
  .col-3 {
    flex: 0 0 auto;
    width: 25%; }
  
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  
  .col-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  
  .col-6 {
    flex: 0 0 auto;
    width: 50%; }
  
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  
  .col-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  
  .col-9 {
    flex: 0 0 auto;
    width: 75%; }
  
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  
  .col-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  
  .col-12 {
    flex: 0 0 auto;
    width: 100%; }
  
  .offset-1 {
    margin-left: 8.33333%; }
  
  .offset-2 {
    margin-left: 16.66667%; }
  
  .offset-3 {
    margin-left: 25%; }
  
  .offset-4 {
    margin-left: 33.33333%; }
  
  .offset-5 {
    margin-left: 41.66667%; }
  
  .offset-6 {
    margin-left: 50%; }
  
  .offset-7 {
    margin-left: 58.33333%; }
  
  .offset-8 {
    margin-left: 66.66667%; }
  
  .offset-9 {
    margin-left: 75%; }
  
  .offset-10 {
    margin-left: 83.33333%; }
  
  .offset-11 {
    margin-left: 91.66667%; }
  
  .g-0,
  .gx-0 {
    --bs-gutter-x: 0; }
  
  .g-0,
  .gy-0 {
    --bs-gutter-y: 0; }
  
  .g-1,
  .gx-1 {
    --bs-gutter-x: 0.5rem; }
  
  .g-1,
  .gy-1 {
    --bs-gutter-y: 0.5rem; }
  
  .g-2,
  .gx-2 {
    --bs-gutter-x: 1rem; }
  
  .g-2,
  .gy-2 {
    --bs-gutter-y: 1rem; }
  
  .g-3,
  .gx-3 {
    --bs-gutter-x: 2rem; }
  
  .g-3,
  .gy-3 {
    --bs-gutter-y: 2rem; }
  
  .g-4,
  .gx-4 {
    --bs-gutter-x: 3rem; }
  
  .g-4,
  .gy-4 {
    --bs-gutter-y: 3rem; }
  
  .g-5,
  .gx-5 {
    --bs-gutter-x: 6rem; }
  
  .g-5,
  .gy-5 {
    --bs-gutter-y: 6rem; }
  
  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%; }
    .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-sm-0 {
      margin-left: 0; }
    .offset-sm-1 {
      margin-left: 8.33333%; }
    .offset-sm-2 {
      margin-left: 16.66667%; }
    .offset-sm-3 {
      margin-left: 25%; }
    .offset-sm-4 {
      margin-left: 33.33333%; }
    .offset-sm-5 {
      margin-left: 41.66667%; }
    .offset-sm-6 {
      margin-left: 50%; }
    .offset-sm-7 {
      margin-left: 58.33333%; }
    .offset-sm-8 {
      margin-left: 66.66667%; }
    .offset-sm-9 {
      margin-left: 75%; }
    .offset-sm-10 {
      margin-left: 83.33333%; }
    .offset-sm-11 {
      margin-left: 91.66667%; }
    .g-sm-0,
    .gx-sm-0 {
      --bs-gutter-x: 0; }
    .g-sm-0,
    .gy-sm-0 {
      --bs-gutter-y: 0; }
    .g-sm-1,
    .gx-sm-1 {
      --bs-gutter-x: 0.5rem; }
    .g-sm-1,
    .gy-sm-1 {
      --bs-gutter-y: 0.5rem; }
    .g-sm-2,
    .gx-sm-2 {
      --bs-gutter-x: 1rem; }
    .g-sm-2,
    .gy-sm-2 {
      --bs-gutter-y: 1rem; }
    .g-sm-3,
    .gx-sm-3 {
      --bs-gutter-x: 2rem; }
    .g-sm-3,
    .gy-sm-3 {
      --bs-gutter-y: 2rem; }
    .g-sm-4,
    .gx-sm-4 {
      --bs-gutter-x: 3rem; }
    .g-sm-4,
    .gy-sm-4 {
      --bs-gutter-y: 3rem; }
    .g-sm-5,
    .gx-sm-5 {
      --bs-gutter-x: 6rem; }
    .g-sm-5,
    .gy-sm-5 {
      --bs-gutter-y: 6rem; } }
  
  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%; }
    .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-md-0 {
      margin-left: 0; }
    .offset-md-1 {
      margin-left: 8.33333%; }
    .offset-md-2 {
      margin-left: 16.66667%; }
    .offset-md-3 {
      margin-left: 25%; }
    .offset-md-4 {
      margin-left: 33.33333%; }
    .offset-md-5 {
      margin-left: 41.66667%; }
    .offset-md-6 {
      margin-left: 50%; }
    .offset-md-7 {
      margin-left: 58.33333%; }
    .offset-md-8 {
      margin-left: 66.66667%; }
    .offset-md-9 {
      margin-left: 75%; }
    .offset-md-10 {
      margin-left: 83.33333%; }
    .offset-md-11 {
      margin-left: 91.66667%; }
    .g-md-0,
    .gx-md-0 {
      --bs-gutter-x: 0; }
    .g-md-0,
    .gy-md-0 {
      --bs-gutter-y: 0; }
    .g-md-1,
    .gx-md-1 {
      --bs-gutter-x: 0.5rem; }
    .g-md-1,
    .gy-md-1 {
      --bs-gutter-y: 0.5rem; }
    .g-md-2,
    .gx-md-2 {
      --bs-gutter-x: 1rem; }
    .g-md-2,
    .gy-md-2 {
      --bs-gutter-y: 1rem; }
    .g-md-3,
    .gx-md-3 {
      --bs-gutter-x: 2rem; }
    .g-md-3,
    .gy-md-3 {
      --bs-gutter-y: 2rem; }
    .g-md-4,
    .gx-md-4 {
      --bs-gutter-x: 3rem; }
    .g-md-4,
    .gy-md-4 {
      --bs-gutter-y: 3rem; }
    .g-md-5,
    .gx-md-5 {
      --bs-gutter-x: 6rem; }
    .g-md-5,
    .gy-md-5 {
      --bs-gutter-y: 6rem; } }
  
  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%; }
    .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-lg-0 {
      margin-left: 0; }
    .offset-lg-1 {
      margin-left: 8.33333%; }
    .offset-lg-2 {
      margin-left: 16.66667%; }
    .offset-lg-3 {
      margin-left: 25%; }
    .offset-lg-4 {
      margin-left: 33.33333%; }
    .offset-lg-5 {
      margin-left: 41.66667%; }
    .offset-lg-6 {
      margin-left: 50%; }
    .offset-lg-7 {
      margin-left: 58.33333%; }
    .offset-lg-8 {
      margin-left: 66.66667%; }
    .offset-lg-9 {
      margin-left: 75%; }
    .offset-lg-10 {
      margin-left: 83.33333%; }
    .offset-lg-11 {
      margin-left: 91.66667%; }
    .g-lg-0,
    .gx-lg-0 {
      --bs-gutter-x: 0; }
    .g-lg-0,
    .gy-lg-0 {
      --bs-gutter-y: 0; }
    .g-lg-1,
    .gx-lg-1 {
      --bs-gutter-x: 0.5rem; }
    .g-lg-1,
    .gy-lg-1 {
      --bs-gutter-y: 0.5rem; }
    .g-lg-2,
    .gx-lg-2 {
      --bs-gutter-x: 1rem; }
    .g-lg-2,
    .gy-lg-2 {
      --bs-gutter-y: 1rem; }
    .g-lg-3,
    .gx-lg-3 {
      --bs-gutter-x: 2rem; }
    .g-lg-3,
    .gy-lg-3 {
      --bs-gutter-y: 2rem; }
    .g-lg-4,
    .gx-lg-4 {
      --bs-gutter-x: 3rem; }
    .g-lg-4,
    .gy-lg-4 {
      --bs-gutter-y: 3rem; }
    .g-lg-5,
    .gx-lg-5 {
      --bs-gutter-x: 6rem; }
    .g-lg-5,
    .gy-lg-5 {
      --bs-gutter-y: 6rem; } }
  
  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%; }
    .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-xl-0 {
      margin-left: 0; }
    .offset-xl-1 {
      margin-left: 8.33333%; }
    .offset-xl-2 {
      margin-left: 16.66667%; }
    .offset-xl-3 {
      margin-left: 25%; }
    .offset-xl-4 {
      margin-left: 33.33333%; }
    .offset-xl-5 {
      margin-left: 41.66667%; }
    .offset-xl-6 {
      margin-left: 50%; }
    .offset-xl-7 {
      margin-left: 58.33333%; }
    .offset-xl-8 {
      margin-left: 66.66667%; }
    .offset-xl-9 {
      margin-left: 75%; }
    .offset-xl-10 {
      margin-left: 83.33333%; }
    .offset-xl-11 {
      margin-left: 91.66667%; }
    .g-xl-0,
    .gx-xl-0 {
      --bs-gutter-x: 0; }
    .g-xl-0,
    .gy-xl-0 {
      --bs-gutter-y: 0; }
    .g-xl-1,
    .gx-xl-1 {
      --bs-gutter-x: 0.5rem; }
    .g-xl-1,
    .gy-xl-1 {
      --bs-gutter-y: 0.5rem; }
    .g-xl-2,
    .gx-xl-2 {
      --bs-gutter-x: 1rem; }
    .g-xl-2,
    .gy-xl-2 {
      --bs-gutter-y: 1rem; }
    .g-xl-3,
    .gx-xl-3 {
      --bs-gutter-x: 2rem; }
    .g-xl-3,
    .gy-xl-3 {
      --bs-gutter-y: 2rem; }
    .g-xl-4,
    .gx-xl-4 {
      --bs-gutter-x: 3rem; }
    .g-xl-4,
    .gy-xl-4 {
      --bs-gutter-y: 3rem; }
    .g-xl-5,
    .gx-xl-5 {
      --bs-gutter-x: 6rem; }
    .g-xl-5,
    .gy-xl-5 {
      --bs-gutter-y: 6rem; } }
  
  @media (min-width: 1400px) {
    .col-xxl {
      flex: 1 0 0%; }
    .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.33333%; }
    .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto; }
    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333%; }
    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66667%; }
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%; }
    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333%; }
    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66667%; }
    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%; }
    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333%; }
    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66667%; }
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%; }
    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333%; }
    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66667%; }
    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%; }
    .offset-xxl-0 {
      margin-left: 0; }
    .offset-xxl-1 {
      margin-left: 8.33333%; }
    .offset-xxl-2 {
      margin-left: 16.66667%; }
    .offset-xxl-3 {
      margin-left: 25%; }
    .offset-xxl-4 {
      margin-left: 33.33333%; }
    .offset-xxl-5 {
      margin-left: 41.66667%; }
    .offset-xxl-6 {
      margin-left: 50%; }
    .offset-xxl-7 {
      margin-left: 58.33333%; }
    .offset-xxl-8 {
      margin-left: 66.66667%; }
    .offset-xxl-9 {
      margin-left: 75%; }
    .offset-xxl-10 {
      margin-left: 83.33333%; }
    .offset-xxl-11 {
      margin-left: 91.66667%; }
    .g-xxl-0,
    .gx-xxl-0 {
      --bs-gutter-x: 0; }
    .g-xxl-0,
    .gy-xxl-0 {
      --bs-gutter-y: 0; }
    .g-xxl-1,
    .gx-xxl-1 {
      --bs-gutter-x: 0.5rem; }
    .g-xxl-1,
    .gy-xxl-1 {
      --bs-gutter-y: 0.5rem; }
    .g-xxl-2,
    .gx-xxl-2 {
      --bs-gutter-x: 1rem; }
    .g-xxl-2,
    .gy-xxl-2 {
      --bs-gutter-y: 1rem; }
    .g-xxl-3,
    .gx-xxl-3 {
      --bs-gutter-x: 2rem; }
    .g-xxl-3,
    .gy-xxl-3 {
      --bs-gutter-y: 2rem; }
    .g-xxl-4,
    .gx-xxl-4 {
      --bs-gutter-x: 3rem; }
    .g-xxl-4,
    .gy-xxl-4 {
      --bs-gutter-y: 3rem; }
    .g-xxl-5,
    .gx-xxl-5 {
      --bs-gutter-x: 6rem; }
    .g-xxl-5,
    .gy-xxl-5 {
      --bs-gutter-y: 6rem; } }
  
  .table {
    --bs-table-bg: transparent;
    --bs-table-striped-color: #434A54;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #434A54;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #434A54;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 2rem;
    color: #434A54;
    vertical-align: top;
    border-color: #dee2e6; }
    .table > :not(caption) > * > * {
      padding: 0.5rem 0.5rem;
      background-color: var(--bs-table-bg);
      background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
      border-bottom-width: 1px; }
    .table > tbody {
      vertical-align: inherit; }
    .table > thead {
      vertical-align: bottom; }
    .table > :not(:last-child) > :last-child > * {
      border-bottom-color: currentColor; }
  
  .caption-top {
    caption-side: top; }
  
  .table-sm > :not(caption) > * > * {
    padding: 0.25rem 0.25rem; }
  
  .table-bordered > :not(caption) > * {
    border-width: 1px 0; }
    .table-bordered > :not(caption) > * > * {
      border-width: 0 1px; }
  
  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0; }
  
  .table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color); }
  
  .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color); }
  
  .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color); }
  
  .table-primary {
    --bs-table-bg: #dbe7f8;
    --bs-table-striped-bg: #d0dbec;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c5d0df;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #cbd6e5;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #c5d0df; }
  
  .table-secondary {
    --bs-table-bg: #e0e2e4;
    --bs-table-striped-bg: #d5d7d9;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cacbcd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #cfd1d3;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cacbcd; }
  
  .table-success {
    --bs-table-bg: #d7f2eb;
    --bs-table-striped-bg: #cce6df;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c2dad4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c7e0d9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #c2dad4; }
  
  .table-info {
    --bs-table-bg: #d8eff8;
    --bs-table-striped-bg: #cde3ec;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c2d7df;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c8dde5;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #c2d7df; }
  
  .table-warning {
    --bs-table-bg: #fdf1d9;
    --bs-table-striped-bg: #f0e5ce;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e4d9c3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #eadfc9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e4d9c3; }
  
  .table-danger {
    --bs-table-bg: #fbddd9;
    --bs-table-striped-bg: #eed2ce;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e2c7c3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e8ccc9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e2c7c3; }
  
  .table-light {
    --bs-table-bg: #E6E9ED;
    --bs-table-striped-bg: #dbdde1;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cfd2d5;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d5d8db;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cfd2d5; }
  
  .table-dark {
    --bs-table-bg: #434A54;
    --bs-table-striped-bg: #4c535d;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #565c65;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #515861;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #565c65; }
  
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  
  .form-label {
    margin-bottom: 0.5rem; }
  
  .col-form-label {
    padding-top: calc(0.375rem + 2px);
    padding-bottom: calc(0.375rem + 2px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  
  .col-form-label-lg {
    padding-top: calc(0.5rem + 2px);
    padding-bottom: calc(0.5rem + 2px);
    font-size: 1.25rem; }
  
  .col-form-label-sm {
    padding-top: calc(0.25rem + 2px);
    padding-bottom: calc(0.25rem + 2px);
    font-size: 0.875rem; }
  
  .form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d; }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #434A54;
    background-color: #f8f9fa;
    background-clip: padding-box;
    border: 2px solid #dee2e6;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control {
        transition: none; } }
    .form-control[type="file"] {
      overflow: hidden; }
      .form-control[type="file"]:not(:disabled):not([readonly]) {
        cursor: pointer; }
    .form-control:focus {
      color: #434A54;
      background-color: white;
      border-color: #a5c4ee;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
    .form-control::-webkit-date-and-time-value {
      height: 1.5em; }
    .form-control::-moz-placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control:-ms-input-placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    .form-control:disabled, .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1; }
    .form-control::file-selector-button {
      padding: 0.375rem 0.75rem;
      margin: -0.375rem -0.75rem;
      -webkit-margin-end: 0.75rem;
              margin-inline-end: 0.75rem;
      color: #434A54;
      background-color: #e9ecef;
      pointer-events: none;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      border-inline-end-width: 2px;
      border-radius: 0;
      transition: all 0.18s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .form-control::file-selector-button {
          transition: none; } }
    .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
      background-color: #dde0e3; }
    .form-control::-webkit-file-upload-button {
      padding: 0.375rem 0.75rem;
      margin: -0.375rem -0.75rem;
      -webkit-margin-end: 0.75rem;
              margin-inline-end: 0.75rem;
      color: #434A54;
      background-color: #e9ecef;
      pointer-events: none;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      border-inline-end-width: 2px;
      border-radius: 0;
      -webkit-transition: all 0.18s ease-in-out;
      transition: all 0.18s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .form-control::-webkit-file-upload-button {
          -webkit-transition: none;
          transition: none; } }
    .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
      background-color: #dde0e3; }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #434A54;
    background-color: transparent;
    border: solid transparent;
    border-width: 2px 0; }
    .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
      padding-right: 0;
      padding-left: 0; }
  
  .form-control-sm {
    min-height: calc(1.5em + 0.5rem + 4px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
    .form-control-sm::file-selector-button {
      padding: 0.25rem 0.5rem;
      margin: -0.25rem -0.5rem;
      -webkit-margin-end: 0.5rem;
              margin-inline-end: 0.5rem; }
    .form-control-sm::-webkit-file-upload-button {
      padding: 0.25rem 0.5rem;
      margin: -0.25rem -0.5rem;
      -webkit-margin-end: 0.5rem;
              margin-inline-end: 0.5rem; }
  
  .form-control-lg {
    min-height: calc(1.5em + 1rem + 4px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
    .form-control-lg::file-selector-button {
      padding: 0.5rem 1rem;
      margin: -0.5rem -1rem;
      -webkit-margin-end: 1rem;
              margin-inline-end: 1rem; }
    .form-control-lg::-webkit-file-upload-button {
      padding: 0.5rem 1rem;
      margin: -0.5rem -1rem;
      -webkit-margin-end: 1rem;
              margin-inline-end: 1rem; }
  
  textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 4px); }
  
  textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 4px); }
  
  textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 4px); }
  
  .form-control-color {
    max-width: 3rem;
    height: auto;
    padding: 0.375rem; }
    .form-control-color:not(:disabled):not([readonly]) {
      cursor: pointer; }
    .form-control-color::-moz-color-swatch {
      height: 1.5em;
      border-radius: 0.25rem; }
    .form-control-color::-webkit-color-swatch {
      height: 1.5em;
      border-radius: 0.25rem; }
  
  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #434A54;
    vertical-align: middle;
    background-color: #f8f9fa;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 2px solid #dee2e6;
    border-radius: 0.25rem;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .form-select:focus {
      border-color: #a5c4ee;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
    .form-select[multiple], .form-select[size]:not([size="1"]) {
      padding-right: 0.75rem;
      background-image: none; }
    .form-select:disabled {
      color: #6c757d;
      background-color: #e9ecef; }
    .form-select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #434A54; }
  
  .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem; }
  
  .form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem; }
  
  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem; }
    .form-check .form-check-input {
      float: left;
      margin-left: -1.5em; }
  
  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #F5F7FA;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-print-color-adjust: exact;
            color-adjust: exact;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-check-input {
        transition: none; } }
    .form-check-input[type="checkbox"] {
      border-radius: 0.25em; }
    .form-check-input[type="radio"] {
      border-radius: 50%; }
    .form-check-input:active {
      filter: brightness(90%); }
    .form-check-input:focus {
      border-color: #a5c4ee;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
    .form-check-input:checked {
      background-color: #4A89DC;
      border-color: #4A89DC; }
      .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
      .form-check-input:checked[type="radio"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
    .form-check-input[type="checkbox"]:indeterminate {
      background-color: #4A89DC;
      border-color: #4A89DC;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
    .form-check-input:disabled {
      pointer-events: none;
      filter: none;
      opacity: 0.5; }
    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
      opacity: 0.5; }
  
  .form-switch {
    padding-left: 2.5em; }
    .form-switch .form-check-input {
      width: 2em;
      margin-left: -2.5em;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
      background-position: left center;
      border-radius: 2em; }
      .form-switch .form-check-input:focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a5c4ee'/%3e%3c/svg%3e"); }
      .form-switch .form-check-input:checked {
        background-position: right center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  
  .form-check-inline {
    display: inline-block;
    margin-right: 1rem; }
  
  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }
    .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
      pointer-events: none;
      filter: none;
      opacity: 0.65; }
  
  .form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .form-range:focus {
      outline: none; }
      .form-range:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
      .form-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
    .form-range::-moz-focus-outer {
      border: 0; }
    .form-range::-webkit-slider-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      background-color: #4A89DC;
      border: 0;
      border-radius: 1rem;
      -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -webkit-appearance: none;
              appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        .form-range::-webkit-slider-thumb {
          -webkit-transition: none;
          transition: none; } }
      .form-range::-webkit-slider-thumb:active {
        background-color: #c9dcf5; }
    .form-range::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    .form-range::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background-color: #4A89DC;
      border: 0;
      border-radius: 1rem;
      -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -moz-appearance: none;
           appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        .form-range::-moz-range-thumb {
          -moz-transition: none;
          transition: none; } }
      .form-range::-moz-range-thumb:active {
        background-color: #c9dcf5; }
    .form-range::-moz-range-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    .form-range:disabled {
      pointer-events: none; }
      .form-range:disabled::-webkit-slider-thumb {
        background-color: #adb5bd; }
      .form-range:disabled::-moz-range-thumb {
        background-color: #adb5bd; }
  
  .form-floating {
    position: relative; }
    .form-floating > .form-control,
    .form-floating > .form-select {
      height: calc(3.5rem + 4px);
      padding: 1rem 0.75rem; }
    .form-floating > label {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: 1rem 0.75rem;
      pointer-events: none;
      border: 2px solid transparent;
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .form-floating > label {
          transition: none; } }
    .form-floating > .form-control::-moz-placeholder {
      color: transparent; }
    .form-floating > .form-control:-ms-input-placeholder {
      color: transparent; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:not(:-moz-placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:not(:-ms-input-placeholder) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-select {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label,
    .form-floating > .form-control:not(:placeholder-shown) ~ label,
    .form-floating > .form-select ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:-webkit-autofill ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
    .input-group > .form-control,
    .input-group > .form-select {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0; }
    .input-group > .form-control:focus,
    .input-group > .form-select:focus {
      z-index: 3; }
    .input-group .btn {
      position: relative;
      z-index: 2; }
      .input-group .btn:focus {
        z-index: 3; }
  
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #434A54;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 2px solid #dee2e6;
    border-radius: 0.25rem; }
  
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  
  .input-group-lg > .form-select,
  .input-group-sm > .form-select {
    padding-right: 1.75rem; }
  
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #37BC9B; }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem 1rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(55, 188, 155, 0.9);
    border-radius: 0.25rem; }
  
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip,
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip {
    display: block; }
  
  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #37BC9B;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2337BC9B' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
      border-color: #37BC9B;
      box-shadow: 0 0 0 0.25rem rgba(55, 188, 155, 0.25); }
  
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  
  .was-validated .form-select:valid, .form-select.is-valid {
    border-color: #37BC9B;
    padding-right: calc(0.75em + 2.3125rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2337BC9B' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 1.75rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
      border-color: #37BC9B;
      box-shadow: 0 0 0 0.25rem rgba(55, 188, 155, 0.25); }
  
  .was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #37BC9B; }
    .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
      background-color: #37BC9B; }
    .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
      box-shadow: 0 0 0 0.25rem rgba(55, 188, 155, 0.25); }
    .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
      color: #37BC9B; }
  
  .form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: .5em; }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #E9573F; }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem 1rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(233, 87, 63, 0.9);
    border-radius: 0.25rem; }
  
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block; }
  
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #E9573F;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E9573F'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E9573F' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
      border-color: #E9573F;
      box-shadow: 0 0 0 0.25rem rgba(233, 87, 63, 0.25); }
  
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  
  .was-validated .form-select:invalid, .form-select.is-invalid {
    border-color: #E9573F;
    padding-right: calc(0.75em + 2.3125rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E9573F'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E9573F' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 1.75rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
      border-color: #E9573F;
      box-shadow: 0 0 0 0.25rem rgba(233, 87, 63, 0.25); }
  
  .was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #E9573F; }
    .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
      background-color: #E9573F; }
    .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
      box-shadow: 0 0 0 0.25rem rgba(233, 87, 63, 0.25); }
    .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
      color: #E9573F; }
  
  .form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: .5em; }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #434A54;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 3rem;
    transition: all 0.18s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .btn {
        transition: none; } }
    .btn:hover {
      color: #434A54; }
    .btn-check:focus + .btn, .btn:focus {
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
    .btn:disabled, .btn.disabled,
    fieldset:disabled .btn {
      pointer-events: none;
      opacity: 0.65; }
  
  .btn-primary {
    color: #fff;
    background-color: #4A89DC;
    border-color: #4A89DC; }
    .btn-primary:hover {
      color: #fff;
      background-color: #3f74bb;
      border-color: #3b6eb0; }
    .btn-check:focus + .btn-primary, .btn-primary:focus {
      color: #fff;
      background-color: #3f74bb;
      border-color: #3b6eb0;
      box-shadow: 0 0 0 0.25rem rgba(101, 155, 225, 0.5); }
    .btn-check:checked + .btn-primary,
    .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #3b6eb0;
      border-color: #3867a5; }
      .btn-check:checked + .btn-primary:focus,
      .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
      .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(101, 155, 225, 0.5); }
    .btn-primary:disabled, .btn-primary.disabled {
      color: #fff;
      background-color: #4A89DC;
      border-color: #4A89DC; }
  
  .btn-secondary {
    color: #fff;
    background-color: #656D78;
    border-color: #656D78; }
    .btn-secondary:hover {
      color: #fff;
      background-color: #565d66;
      border-color: #515760; }
    .btn-check:focus + .btn-secondary, .btn-secondary:focus {
      color: #fff;
      background-color: #565d66;
      border-color: #515760;
      box-shadow: 0 0 0 0.25rem rgba(124, 131, 140, 0.5); }
    .btn-check:checked + .btn-secondary,
    .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
    .show > .btn-secondary.dropdown-toggle {
      color: #fff;
      background-color: #515760;
      border-color: #4c525a; }
      .btn-check:checked + .btn-secondary:focus,
      .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
      .show > .btn-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(124, 131, 140, 0.5); }
    .btn-secondary:disabled, .btn-secondary.disabled {
      color: #fff;
      background-color: #656D78;
      border-color: #656D78; }
  
  .btn-success {
    color: #fff;
    background-color: #37BC9B;
    border-color: #37BC9B; }
    .btn-success:hover {
      color: #fff;
      background-color: #2fa084;
      border-color: #2c967c; }
    .btn-check:focus + .btn-success, .btn-success:focus {
      color: #fff;
      background-color: #2fa084;
      border-color: #2c967c;
      box-shadow: 0 0 0 0.25rem rgba(85, 198, 170, 0.5); }
    .btn-check:checked + .btn-success,
    .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
    .show > .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #2c967c;
      border-color: #298d74; }
      .btn-check:checked + .btn-success:focus,
      .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
      .show > .btn-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(85, 198, 170, 0.5); }
    .btn-success:disabled, .btn-success.disabled {
      color: #fff;
      background-color: #37BC9B;
      border-color: #37BC9B; }
  
  .btn-info {
    color: #fff;
    background-color: #3BAFDA;
    border-color: #3BAFDA; }
    .btn-info:hover {
      color: #fff;
      background-color: #3295b9;
      border-color: #2f8cae; }
    .btn-check:focus + .btn-info, .btn-info:focus {
      color: #fff;
      background-color: #3295b9;
      border-color: #2f8cae;
      box-shadow: 0 0 0 0.25rem rgba(88, 187, 224, 0.5); }
    .btn-check:checked + .btn-info,
    .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
    .show > .btn-info.dropdown-toggle {
      color: #fff;
      background-color: #2f8cae;
      border-color: #2c83a4; }
      .btn-check:checked + .btn-info:focus,
      .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
      .show > .btn-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(88, 187, 224, 0.5); }
    .btn-info:disabled, .btn-info.disabled {
      color: #fff;
      background-color: #3BAFDA;
      border-color: #3BAFDA; }
  
  .btn-warning {
    color: #000;
    background-color: #F6BB42;
    border-color: #F6BB42; }
    .btn-warning:hover {
      color: #000;
      background-color: #f7c55e;
      border-color: #f7c255; }
    .btn-check:focus + .btn-warning, .btn-warning:focus {
      color: #000;
      background-color: #f7c55e;
      border-color: #f7c255;
      box-shadow: 0 0 0 0.25rem rgba(209, 159, 56, 0.5); }
    .btn-check:checked + .btn-warning,
    .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
    .show > .btn-warning.dropdown-toggle {
      color: #000;
      background-color: #f8c968;
      border-color: #f7c255; }
      .btn-check:checked + .btn-warning:focus,
      .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
      .show > .btn-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(209, 159, 56, 0.5); }
    .btn-warning:disabled, .btn-warning.disabled {
      color: #000;
      background-color: #F6BB42;
      border-color: #F6BB42; }
  
  .btn-danger {
    color: #fff;
    background-color: #E9573F;
    border-color: #E9573F; }
    .btn-danger:hover {
      color: #fff;
      background-color: #c64a36;
      border-color: #ba4632; }
    .btn-check:focus + .btn-danger, .btn-danger:focus {
      color: #fff;
      background-color: #c64a36;
      border-color: #ba4632;
      box-shadow: 0 0 0 0.25rem rgba(236, 112, 92, 0.5); }
    .btn-check:checked + .btn-danger,
    .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
    .show > .btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #ba4632;
      border-color: #af412f; }
      .btn-check:checked + .btn-danger:focus,
      .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
      .show > .btn-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(236, 112, 92, 0.5); }
    .btn-danger:disabled, .btn-danger.disabled {
      color: #fff;
      background-color: #E9573F;
      border-color: #E9573F; }
  
  .btn-light {
    color: #000;
    background-color: #E6E9ED;
    border-color: #E6E9ED; }
    .btn-light:hover {
      color: #000;
      background-color: #eaecf0;
      border-color: #e9ebef; }
    .btn-check:focus + .btn-light, .btn-light:focus {
      color: #000;
      background-color: #eaecf0;
      border-color: #e9ebef;
      box-shadow: 0 0 0 0.25rem rgba(196, 198, 201, 0.5); }
    .btn-check:checked + .btn-light,
    .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
    .show > .btn-light.dropdown-toggle {
      color: #000;
      background-color: #ebedf1;
      border-color: #e9ebef; }
      .btn-check:checked + .btn-light:focus,
      .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
      .show > .btn-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(196, 198, 201, 0.5); }
    .btn-light:disabled, .btn-light.disabled {
      color: #000;
      background-color: #E6E9ED;
      border-color: #E6E9ED; }
  
  .btn-dark {
    color: #fff;
    background-color: #434A54;
    border-color: #434A54; }
    .btn-dark:hover {
      color: #fff;
      background-color: #393f47;
      border-color: #363b43; }
    .btn-check:focus + .btn-dark, .btn-dark:focus {
      color: #fff;
      background-color: #393f47;
      border-color: #363b43;
      box-shadow: 0 0 0 0.25rem rgba(95, 101, 110, 0.5); }
    .btn-check:checked + .btn-dark,
    .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
    .show > .btn-dark.dropdown-toggle {
      color: #fff;
      background-color: #363b43;
      border-color: #32383f; }
      .btn-check:checked + .btn-dark:focus,
      .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
      .show > .btn-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(95, 101, 110, 0.5); }
    .btn-dark:disabled, .btn-dark.disabled {
      color: #fff;
      background-color: #434A54;
      border-color: #434A54; }
  
  .btn-outline-primary {
    color: #4A89DC;
    border-color: #4A89DC; }
    .btn-outline-primary:hover {
      color: #fff;
      background-color: #4A89DC;
      border-color: #4A89DC; }
    .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.5); }
    .btn-check:checked + .btn-outline-primary,
    .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
      color: #fff;
      background-color: #4A89DC;
      border-color: #4A89DC; }
      .btn-check:checked + .btn-outline-primary:focus,
      .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.5); }
    .btn-outline-primary:disabled, .btn-outline-primary.disabled {
      color: #4A89DC;
      background-color: transparent; }
  
  .btn-outline-secondary {
    color: #656D78;
    border-color: #656D78; }
    .btn-outline-secondary:hover {
      color: #fff;
      background-color: #656D78;
      border-color: #656D78; }
    .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
      box-shadow: 0 0 0 0.25rem rgba(101, 109, 120, 0.5); }
    .btn-check:checked + .btn-outline-secondary,
    .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
      color: #fff;
      background-color: #656D78;
      border-color: #656D78; }
      .btn-check:checked + .btn-outline-secondary:focus,
      .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(101, 109, 120, 0.5); }
    .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
      color: #656D78;
      background-color: transparent; }
  
  .btn-outline-success {
    color: #37BC9B;
    border-color: #37BC9B; }
    .btn-outline-success:hover {
      color: #fff;
      background-color: #37BC9B;
      border-color: #37BC9B; }
    .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
      box-shadow: 0 0 0 0.25rem rgba(55, 188, 155, 0.5); }
    .btn-check:checked + .btn-outline-success,
    .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
      color: #fff;
      background-color: #37BC9B;
      border-color: #37BC9B; }
      .btn-check:checked + .btn-outline-success:focus,
      .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(55, 188, 155, 0.5); }
    .btn-outline-success:disabled, .btn-outline-success.disabled {
      color: #37BC9B;
      background-color: transparent; }
  
  .btn-outline-info {
    color: #3BAFDA;
    border-color: #3BAFDA; }
    .btn-outline-info:hover {
      color: #fff;
      background-color: #3BAFDA;
      border-color: #3BAFDA; }
    .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
      box-shadow: 0 0 0 0.25rem rgba(59, 175, 218, 0.5); }
    .btn-check:checked + .btn-outline-info,
    .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
      color: #fff;
      background-color: #3BAFDA;
      border-color: #3BAFDA; }
      .btn-check:checked + .btn-outline-info:focus,
      .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(59, 175, 218, 0.5); }
    .btn-outline-info:disabled, .btn-outline-info.disabled {
      color: #3BAFDA;
      background-color: transparent; }
  
  .btn-outline-warning {
    color: #F6BB42;
    border-color: #F6BB42; }
    .btn-outline-warning:hover {
      color: #000;
      background-color: #F6BB42;
      border-color: #F6BB42; }
    .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
      box-shadow: 0 0 0 0.25rem rgba(246, 187, 66, 0.5); }
    .btn-check:checked + .btn-outline-warning,
    .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
      color: #000;
      background-color: #F6BB42;
      border-color: #F6BB42; }
      .btn-check:checked + .btn-outline-warning:focus,
      .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(246, 187, 66, 0.5); }
    .btn-outline-warning:disabled, .btn-outline-warning.disabled {
      color: #F6BB42;
      background-color: transparent; }
  
  .btn-outline-danger {
    color: #E9573F;
    border-color: #E9573F; }
    .btn-outline-danger:hover {
      color: #fff;
      background-color: #E9573F;
      border-color: #E9573F; }
    .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
      box-shadow: 0 0 0 0.25rem rgba(233, 87, 63, 0.5); }
    .btn-check:checked + .btn-outline-danger,
    .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
      color: #fff;
      background-color: #E9573F;
      border-color: #E9573F; }
      .btn-check:checked + .btn-outline-danger:focus,
      .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(233, 87, 63, 0.5); }
    .btn-outline-danger:disabled, .btn-outline-danger.disabled {
      color: #E9573F;
      background-color: transparent; }
  
  .btn-outline-light {
    color: #E6E9ED;
    border-color: #E6E9ED; }
    .btn-outline-light:hover {
      color: #000;
      background-color: #E6E9ED;
      border-color: #E6E9ED; }
    .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
      box-shadow: 0 0 0 0.25rem rgba(230, 233, 237, 0.5); }
    .btn-check:checked + .btn-outline-light,
    .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
      color: #000;
      background-color: #E6E9ED;
      border-color: #E6E9ED; }
      .btn-check:checked + .btn-outline-light:focus,
      .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(230, 233, 237, 0.5); }
    .btn-outline-light:disabled, .btn-outline-light.disabled {
      color: #E6E9ED;
      background-color: transparent; }
  
  .btn-outline-dark {
    color: #434A54;
    border-color: #434A54; }
    .btn-outline-dark:hover {
      color: #fff;
      background-color: #434A54;
      border-color: #434A54; }
    .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
      box-shadow: 0 0 0 0.25rem rgba(67, 74, 84, 0.5); }
    .btn-check:checked + .btn-outline-dark,
    .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
      color: #fff;
      background-color: #434A54;
      border-color: #434A54; }
      .btn-check:checked + .btn-outline-dark:focus,
      .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
        box-shadow: 0 0 0 0.25rem rgba(67, 74, 84, 0.5); }
    .btn-outline-dark:disabled, .btn-outline-dark.disabled {
      color: #434A54;
      background-color: transparent; }
  
  .btn-link {
    font-weight: 400;
    color: #4A89DC;
    text-decoration: underline; }
    .btn-link:hover {
      color: #3b6eb0; }
    .btn-link:disabled, .btn-link.disabled {
      color: #6c757d; }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  
  .fade {
    transition: opacity 0.15s linear; }
    @media (prefers-reduced-motion: reduce) {
      .fade {
        transition: none; } }
    .fade:not(.show) {
      opacity: 0; }
  
  .collapse:not(.show) {
    display: none; }
  
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing {
        transition: none; } }
  
  .dropup,
  .dropend,
  .dropdown,
  .dropstart {
    position: relative; }
  
  .dropdown-toggle {
    white-space: nowrap; }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent; }
    .dropdown-toggle:empty::after {
      margin-left: 0; }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #434A54;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
    .dropdown-menu[style] {
      right: auto !important; }
  
  .dropdown-menu-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }
  
  .dropdown-menu-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; }
  
  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
    .dropdown-menu-sm-end {
      --bs-position: end;
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }
  
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
    .dropdown-menu-md-end {
      --bs-position: end;
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }
  
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
    .dropdown-menu-lg-end {
      --bs-position: end;
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }
  
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
    .dropdown-menu-xl-end {
      --bs-position: end;
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }
  
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
      --bs-position: start;
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */; }
    .dropdown-menu-xxl-end {
      --bs-position: end;
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */; } }
  
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropend .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropend .dropdown-toggle::after {
    vertical-align: 0; }
  
  .dropstart .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  
  .dropstart .dropdown-toggle::after {
    display: none; }
  
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0; }
  
  .dropstart .dropdown-toggle::before {
    vertical-align: 0; }
  
  .dropdown-divider {
    height: 0;
    margin: 1rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 2rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    .dropdown-item:hover, .dropdown-item:focus {
      color: #1e2125;
      background-color: #f8f9fa; }
    .dropdown-item.active, .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #4A89DC; }
    .dropdown-item.disabled, .dropdown-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: transparent; }
  
  .dropdown-menu.show {
    display: block; }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 2rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap; }
  
  .dropdown-item-text {
    display: block;
    padding: 0.5rem 2rem;
    color: #212529; }
  
  .dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, 0.15); }
    .dropdown-menu-dark .dropdown-item {
      color: #dee2e6; }
      .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.15); }
      .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
        color: #fff;
        background-color: #4A89DC; }
      .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
        color: #adb5bd; }
    .dropdown-menu-dark .dropdown-divider {
      border-color: rgba(0, 0, 0, 0.15); }
    .dropdown-menu-dark .dropdown-item-text {
      color: #dee2e6; }
    .dropdown-menu-dark .dropdown-header {
      color: #adb5bd; }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
    .btn-group > .btn,
    .btn-group-vertical > .btn {
      position: relative;
      flex: 1 1 auto; }
    .btn-group > .btn-check:checked + .btn,
    .btn-group > .btn-check:focus + .btn,
    .btn-group > .btn:hover,
    .btn-group > .btn:focus,
    .btn-group > .btn:active,
    .btn-group > .btn.active,
    .btn-group-vertical > .btn-check:checked + .btn,
    .btn-group-vertical > .btn-check:focus + .btn,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .btn-toolbar .input-group {
      width: auto; }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  
  .dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem; }
    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropend .dropdown-toggle-split::after {
      margin-left: 0; }
    .dropstart .dropdown-toggle-split::before {
      margin-right: 0; }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .btn-group-vertical > .btn,
    .btn-group-vertical > .btn-group {
      width: 100%; }
    .btn-group-vertical > .btn:not(:first-child),
    .btn-group-vertical > .btn-group:not(:first-child) {
      margin-top: -1px; }
    .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group-vertical > .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .btn-group-vertical > .btn ~ .btn,
    .btn-group-vertical > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .nav-link {
        transition: none; } }
    .nav-link.disabled {
      color: #6c757d;
      pointer-events: none;
      cursor: default; }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
    .nav-tabs .nav-link {
      margin-bottom: -1px;
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
      .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
        border-color: #e9ecef #e9ecef #dee2e6; }
      .nav-tabs .nav-link.disabled {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent; }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #495057;
      background-color: #F5F7FA;
      border-color: #dee2e6 #dee2e6 #F5F7FA; }
    .nav-tabs .dropdown-menu {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem; }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #4A89DC; }
  
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center; }
  
  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }
  
  .tab-content > .tab-pane {
    display: none; }
  
  .tab-content > .active {
    display: block; }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .navbar > .container,
    .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
      display: flex;
      flex-wrap: inherit;
      align-items: center;
      justify-content: space-between; }
  
  .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap; }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0; }
    .navbar-nav .dropdown-menu {
      position: static; }
  
  .navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  
  .navbar-collapse {
    align-items: center;
    width: 100%; }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3rem;
    transition: box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .navbar-toggler {
        transition: none; } }
    .navbar-toggler:hover {
      text-decoration: none; }
    .navbar-toggler:focus {
      text-decoration: none;
      outline: 0;
      box-shadow: 0 0 0 0.25rem; }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-wrap: nowrap;
      justify-content: flex-start; }
      .navbar-expand-sm .navbar-nav {
        flex-direction: row; }
        .navbar-expand-sm .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-sm .navbar-collapse {
        display: flex !important; }
      .navbar-expand-sm .navbar-toggler {
        display: none; } }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start; }
      .navbar-expand-md .navbar-nav {
        flex-direction: row; }
        .navbar-expand-md .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-md .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-md .navbar-collapse {
        display: flex !important; }
      .navbar-expand-md .navbar-toggler {
        display: none; } }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start; }
      .navbar-expand-lg .navbar-nav {
        flex-direction: row; }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-lg .navbar-collapse {
        display: flex !important; }
      .navbar-expand-lg .navbar-toggler {
        display: none; } }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-wrap: nowrap;
      justify-content: flex-start; }
      .navbar-expand-xl .navbar-nav {
        flex-direction: row; }
        .navbar-expand-xl .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-xl .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-xl .navbar-collapse {
        display: flex !important; }
      .navbar-expand-xl .navbar-toggler {
        display: none; } }
  
  @media (min-width: 1400px) {
    .navbar-expand-xxl {
      flex-wrap: nowrap;
      justify-content: flex-start; }
      .navbar-expand-xxl .navbar-nav {
        flex-direction: row; }
        .navbar-expand-xxl .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-xxl .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-xxl .navbar-collapse {
        display: flex !important; }
      .navbar-expand-xxl .navbar-toggler {
        display: none; } }
  
  .navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand .navbar-nav {
      flex-direction: row; }
      .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand .navbar-collapse {
      display: flex !important; }
    .navbar-expand .navbar-toggler {
      display: none; }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
      color: rgba(0, 0, 0, 0.9); }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.55); }
    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
      color: rgba(0, 0, 0, 0.7); }
    .navbar-light .navbar-nav .nav-link.disabled {
      color: rgba(0, 0, 0, 0.3); }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    border-color: rgba(0, 0, 0, 0.1); }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.55); }
    .navbar-light .navbar-text a,
    .navbar-light .navbar-text a:hover,
    .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }
  
  .navbar-dark .navbar-brand {
    color: #fff; }
    .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
      color: #fff; }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.55); }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
      color: rgba(255, 255, 255, 0.75); }
    .navbar-dark .navbar-nav .nav-link.disabled {
      color: rgba(255, 255, 255, 0.25); }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1); }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.55); }
    .navbar-dark .navbar-text a,
    .navbar-dark .navbar-text a:hover,
    .navbar-dark .navbar-text a:focus {
      color: #fff; }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem; }
    .card > hr {
      margin-right: 0;
      margin-left: 0; }
    .card > .list-group {
      border-top: inherit;
      border-bottom: inherit; }
      .card > .list-group:first-child {
        border-top-width: 0;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px); }
      .card > .list-group:last-child {
        border-bottom-width: 0;
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px); }
    .card > .card-header + .list-group,
    .card > .list-group + .card-footer {
      border-top: 0; }
  
  .card-body {
    flex: 1 1 auto;
    padding: 2rem 2rem; }
  
  .card-title {
    margin-bottom: 1rem; }
  
  .card-subtitle {
    margin-top: -0.5rem;
    margin-bottom: 0; }
  
  .card-text:last-child {
    margin-bottom: 0; }
  
  .card-link:hover {
    text-decoration: none; }
  
  .card-link + .card-link {
    margin-left: 2rem /* rtl:ignore */; }
  
  .card-header {
    padding: 1rem 2rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    .card-header:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  
  .card-footer {
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .card-footer:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  
  .card-header-tabs {
    margin-right: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
    border-bottom: 0; }
    .card-header-tabs .nav-link.active {
      background-color: #fff;
      border-bottom-color: #fff; }
  
  .card-header-pills {
    margin-right: -1rem;
    margin-left: -1rem; }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2rem;
    border-radius: calc(0.25rem - 1px); }
  
  .card-img,
  .card-img-top,
  .card-img-bottom {
    width: 100%; }
  
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  
  .card-group > .card {
    margin-bottom: 0.75rem; }
  
  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }
  
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #434A54;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
    overflow-anchor: none;
    transition: all 0.18s ease-in-out, border-radius 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button {
        transition: none; } }
    .accordion-button.collapsed {
      border-bottom-width: 0; }
    .accordion-button:not(.collapsed) {
      color: #437bc6;
      background-color: #edf3fc; }
      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23437bc6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg); }
    .accordion-button::after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      content: "";
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23434A54'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 1.25rem;
      transition: transform 0.2s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .accordion-button::after {
          transition: none; } }
    .accordion-button:hover {
      z-index: 2; }
    .accordion-button:focus {
      z-index: 3;
      border-color: #a5c4ee;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
  
  .accordion-header {
    margin-bottom: 0; }
  
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 1px;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 1px;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  
  .accordion-collapse {
    border: solid rgba(0, 0, 0, 0.125);
    border-width: 0 1px; }
  
  .accordion-body {
    padding: 1rem 1.25rem; }
  
  .accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0; }
  
  .accordion-flush .accordion-collapse {
    border-width: 0; }
  
  .accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none; }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      float: left;
      padding-right: 0.5rem;
      color: #6c757d;
      content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }
  
  .breadcrumb-item.active {
    color: #6c757d; }
  
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none; }
  
  .page-link {
    position: relative;
    display: block;
    color: #4A89DC;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .page-link {
        transition: none; } }
    .page-link:hover {
      z-index: 2;
      color: #3b6eb0;
      background-color: #e9ecef;
      border-color: #dee2e6; }
    .page-link:focus {
      z-index: 3;
      color: #3b6eb0;
      background-color: #e9ecef;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25); }
  
  .page-item:not(:first-child) .page-link {
    margin-left: -1px; }
  
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4A89DC;
    border-color: #4A89DC; }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6; }
  
  .page-link {
    padding: 0.375rem 0.75rem; }
  
  .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem; }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem; }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }
  
  .badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem; }
    .badge:empty {
      display: none; }
  
  .btn .badge {
    position: relative;
    top: -1px; }
  
  .alert {
    position: relative;
    padding: 2rem 2rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  
  .alert-heading {
    color: inherit; }
  
  .alert-link {
    font-weight: 700; }
  
  .alert-dismissible {
    padding-right: 6rem; }
    .alert-dismissible .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 2.5rem 2rem; }
  
  .alert-primary {
    color: #2c5284;
    background-color: #dbe7f8;
    border-color: #c9dcf5; }
    .alert-primary .alert-link {
      color: #23426a; }
  
  .alert-secondary {
    color: #3d4148;
    background-color: #e0e2e4;
    border-color: #d1d3d7; }
    .alert-secondary .alert-link {
      color: #31343a; }
  
  .alert-success {
    color: #21715d;
    background-color: #d7f2eb;
    border-color: #c3ebe1; }
    .alert-success .alert-link {
      color: #1a5a4a; }
  
  .alert-info {
    color: #236983;
    background-color: #d8eff8;
    border-color: #c4e7f4; }
    .alert-info .alert-link {
      color: #1c5469; }
  
  .alert-warning {
    color: #947028;
    background-color: #fdf1d9;
    border-color: #fcebc6; }
    .alert-warning .alert-link {
      color: #765a20; }
  
  .alert-danger {
    color: #8c3426;
    background-color: #fbddd9;
    border-color: #f8cdc5; }
    .alert-danger .alert-link {
      color: #702a1e; }
  
  .alert-light {
    color: #8a8c8e;
    background-color: #fafbfb;
    border-color: #f8f8fa; }
    .alert-light .alert-link {
      color: #6e7072; }
  
  .alert-dark {
    color: #282c32;
    background-color: #d9dbdd;
    border-color: #c7c9cc; }
    .alert-dark .alert-link {
      color: #202328; }
  
  @-webkit-keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem; } }
  
  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem; } }
  
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #4A89DC;
    transition: width 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .progress-bar {
        transition: none; } }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  
  .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
            animation: 1s linear infinite progress-bar-stripes; }
    @media (prefers-reduced-motion: reduce) {
      .progress-bar-animated {
        -webkit-animation: none;
                animation: none; } }
  
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem; }
  
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit; }
    .list-group-item-action:hover, .list-group-item-action:focus {
      z-index: 1;
      color: #495057;
      text-decoration: none;
      background-color: #f8f9fa; }
    .list-group-item-action:active {
      color: #434A54;
      background-color: #e9ecef; }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 1rem 2rem;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
    .list-group-item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
    .list-group-item:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
    .list-group-item.disabled, .list-group-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: #fff; }
    .list-group-item.active {
      z-index: 2;
      color: #fff;
      background-color: #4A89DC;
      border-color: #4A89DC; }
    .list-group-item + .list-group-item {
      border-top-width: 0; }
      .list-group-item + .list-group-item.active {
        margin-top: -1px;
        border-top-width: 1px; }
  
  .list-group-horizontal {
    flex-direction: row; }
    .list-group-horizontal > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; }
  
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      flex-direction: row; }
      .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      flex-direction: row; }
      .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .list-group-horizontal-md > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      flex-direction: row; }
      .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      flex-direction: row; }
      .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  
  @media (min-width: 1400px) {
    .list-group-horizontal-xxl {
      flex-direction: row; }
      .list-group-horizontal-xxl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      .list-group-horizontal-xxl > .list-group-item.active {
        margin-top: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  
  .list-group-flush {
    border-radius: 0; }
    .list-group-flush > .list-group-item {
      border-width: 0 0 1px; }
      .list-group-flush > .list-group-item:last-child {
        border-bottom-width: 0; }
  
  .list-group-item-primary {
    color: #2c5284;
    background-color: #dbe7f8; }
    .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
      color: #2c5284;
      background-color: #c5d0df; }
    .list-group-item-primary.list-group-item-action.active {
      color: #fff;
      background-color: #2c5284;
      border-color: #2c5284; }
  
  .list-group-item-secondary {
    color: #3d4148;
    background-color: #e0e2e4; }
    .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
      color: #3d4148;
      background-color: #cacbcd; }
    .list-group-item-secondary.list-group-item-action.active {
      color: #fff;
      background-color: #3d4148;
      border-color: #3d4148; }
  
  .list-group-item-success {
    color: #21715d;
    background-color: #d7f2eb; }
    .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
      color: #21715d;
      background-color: #c2dad4; }
    .list-group-item-success.list-group-item-action.active {
      color: #fff;
      background-color: #21715d;
      border-color: #21715d; }
  
  .list-group-item-info {
    color: #236983;
    background-color: #d8eff8; }
    .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
      color: #236983;
      background-color: #c2d7df; }
    .list-group-item-info.list-group-item-action.active {
      color: #fff;
      background-color: #236983;
      border-color: #236983; }
  
  .list-group-item-warning {
    color: #947028;
    background-color: #fdf1d9; }
    .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
      color: #947028;
      background-color: #e4d9c3; }
    .list-group-item-warning.list-group-item-action.active {
      color: #fff;
      background-color: #947028;
      border-color: #947028; }
  
  .list-group-item-danger {
    color: #8c3426;
    background-color: #fbddd9; }
    .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
      color: #8c3426;
      background-color: #e2c7c3; }
    .list-group-item-danger.list-group-item-action.active {
      color: #fff;
      background-color: #8c3426;
      border-color: #8c3426; }
  
  .list-group-item-light {
    color: #8a8c8e;
    background-color: #fafbfb; }
    .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
      color: #8a8c8e;
      background-color: #e1e2e2; }
    .list-group-item-light.list-group-item-action.active {
      color: #fff;
      background-color: #8a8c8e;
      border-color: #8a8c8e; }
  
  .list-group-item-dark {
    color: #282c32;
    background-color: #d9dbdd; }
    .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
      color: #282c32;
      background-color: #c3c5c7; }
    .list-group-item-dark.list-group-item-action.active {
      color: #fff;
      background-color: #282c32;
      border-color: #282c32; }
  
  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5; }
    .btn-close:hover {
      color: #000;
      text-decoration: none;
      opacity: 0.75; }
    .btn-close:focus {
      outline: none;
      box-shadow: 0 0 0 0.25rem rgba(74, 137, 220, 0.25);
      opacity: 1; }
    .btn-close:disabled, .btn-close.disabled {
      pointer-events: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      opacity: 0.25; }
  
  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%); }
  
  .toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
    .toast:not(.showing):not(.show) {
      opacity: 0; }
    .toast.hide {
      display: none; }
  
  .toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none; }
    .toast-container > :not(:last-child) {
      margin-bottom: 0.75rem; }
  
  .toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
    .toast-header .btn-close {
      margin-right: -0.375rem;
      margin-left: 0.75rem; }
  
  .toast-body {
    padding: 0.75rem; }
  
  .modal-open {
    overflow: hidden; }
    .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px); }
      @media (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
          transition: none; } }
    .modal.show .modal-dialog {
      transform: none; }
    .modal.modal-static .modal-dialog {
      transform: scale(1.02); }
  
  .modal-dialog-scrollable {
    height: calc(100% - 1rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: 100%;
      overflow: hidden; }
    .modal-dialog-scrollable .modal-body {
      overflow-y: auto; }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0; }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
    .modal-backdrop.fade {
      opacity: 0; }
    .modal-backdrop.show {
      opacity: 0.5; }
  
  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    .modal-header .btn-close {
      padding: 1rem 1rem;
      margin: -1rem -1rem -1rem auto; }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem; }
  
  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 1.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px); }
    .modal-footer > * {
      margin: 0.25rem; }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .modal-dialog-scrollable {
      height: calc(100% - 3.5rem); }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
    .modal-sm {
      max-width: 300px; } }
  
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px; } }
  
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px; } }
  
  .modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen .modal-header {
      border-radius: 0; }
    .modal-fullscreen .modal-body {
      overflow-y: auto; }
    .modal-fullscreen .modal-footer {
      border-radius: 0; }
  
  @media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
      .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0; }
      .modal-fullscreen-sm-down .modal-header {
        border-radius: 0; }
      .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto; }
      .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0; } }
  
  @media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
      .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0; }
      .modal-fullscreen-md-down .modal-header {
        border-radius: 0; }
      .modal-fullscreen-md-down .modal-body {
        overflow-y: auto; }
      .modal-fullscreen-md-down .modal-footer {
        border-radius: 0; } }
  
  @media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
      .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0; }
      .modal-fullscreen-lg-down .modal-header {
        border-radius: 0; }
      .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto; }
      .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0; } }
  
  @media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
      .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0; }
      .modal-fullscreen-xl-down .modal-header {
        border-radius: 0; }
      .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto; }
      .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0; } }
  
  @media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
      .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0; }
      .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0; }
      .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto; }
      .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0; } }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
    .tooltip.show {
      opacity: 0.9; }
    .tooltip .tooltip-arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem; }
      .tooltip .tooltip-arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid; }
  
  .bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
    padding: 0.4rem 0; }
    .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
      bottom: 0; }
      .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
        top: -1px;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000; }
  
  .bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
    padding: 0 0.4rem; }
    .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem; }
      .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
        right: -1px;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000; }
  
  .bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
    padding: 0.4rem 0; }
    .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
      top: 0; }
      .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
        bottom: -1px;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000; }
  
  .bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
    padding: 0 0.4rem; }
    .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem; }
      .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
        left: -1px;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000; }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.5rem 1rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem; }
    .popover .popover-arrow {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem; }
      .popover .popover-arrow::before, .popover .popover-arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid; }
  
  .bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
    margin-bottom: 0.5rem !important; }
    .bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
      bottom: calc(-0.5rem - 1px); }
      .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25); }
      .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff; }
  
  .bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
    margin-left: 0.5rem !important; }
    .bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
      left: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25); }
      .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff; }
  
  .bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
    margin-top: 0.5rem !important; }
    .bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
      top: calc(-0.5rem - 1px); }
      .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25); }
      .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff; }
    .bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1rem;
      margin-left: -0.5rem;
      content: "";
      border-bottom: 1px solid #f0f0f0; }
  
  .bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
    margin-right: 0.5rem !important; }
    .bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
      right: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25); }
      .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff; }
  
  .popover-header {
    padding: 0.5rem 2rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid #d8d8d8;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    .popover-header:empty {
      display: none; }
  
  .popover-body {
    padding: 2rem 2rem;
    color: #434A54; }
  
  .carousel {
    position: relative; }
  
  .carousel.pointer-event {
    touch-action: pan-y; }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
    .carousel-inner::after {
      display: block;
      clear: both;
      content: ""; }
  
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: transform 0.6s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-item {
        transition: none; } }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block; }
  
  /* rtl:begin:ignore */
  .carousel-item-next:not(.carousel-item-start),
  .active.carousel-item-end {
    transform: translateX(100%); }
  
  .carousel-item-prev:not(.carousel-item-end),
  .active.carousel-item-start {
    transform: translateX(-100%); }
  
  /* rtl:end:ignore */
  .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none; }
  
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1; }
  
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-fade .active.carousel-item-start,
      .carousel-fade .active.carousel-item-end {
        transition: none; } }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-control-prev,
      .carousel-control-next {
        transition: none; } }
    .carousel-control-prev:hover, .carousel-control-prev:focus,
    .carousel-control-next:hover,
    .carousel-control-next:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      opacity: 0.9; }
  
  .carousel-control-prev {
    left: 0; }
  
  .carousel-control-next {
    right: 0; }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%; }
  
  /* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
    .carousel-indicators li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: 0.5;
      transition: opacity 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-indicators li {
          transition: none; } }
    .carousel-indicators .active {
      opacity: 1; }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center; }
  
  .carousel-dark .carousel-control-prev-icon,
  .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100); }
  
  .carousel-dark .carousel-indicators li {
    background-color: #000; }
  
  .carousel-dark .carousel-caption {
    color: #000; }
  
  @-webkit-keyframes spinner-border {
    to {
      transform: rotate(360deg) /* rtl:ignore */; } }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg) /* rtl:ignore */; } }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
            animation: 0.75s linear infinite spinner-border; }
  
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }
  
  @-webkit-keyframes spinner-grow {
    0% {
      transform: scale(0); }
    50% {
      opacity: 1;
      transform: none; } }
  
  @keyframes spinner-grow {
    0% {
      transform: scale(0); }
    50% {
      opacity: 1;
      transform: none; } }
  
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
            animation: 0.75s linear infinite spinner-grow; }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  
  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
      -webkit-animation-duration: 1.5s;
              animation-duration: 1.5s; } }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  
  .link-primary {
    color: #4A89DC; }
    .link-primary:hover, .link-primary:focus {
      color: #3b6eb0; }
  
  .link-secondary {
    color: #656D78; }
    .link-secondary:hover, .link-secondary:focus {
      color: #515760; }
  
  .link-success {
    color: #37BC9B; }
    .link-success:hover, .link-success:focus {
      color: #2c967c; }
  
  .link-info {
    color: #3BAFDA; }
    .link-info:hover, .link-info:focus {
      color: #2f8cae; }
  
  .link-warning {
    color: #F6BB42; }
    .link-warning:hover, .link-warning:focus {
      color: #f8c968; }
  
  .link-danger {
    color: #E9573F; }
    .link-danger:hover, .link-danger:focus {
      color: #ba4632; }
  
  .link-light {
    color: #E6E9ED; }
    .link-light:hover, .link-light:focus {
      color: #ebedf1; }
  
  .link-dark {
    color: #434A54; }
    .link-dark:hover, .link-dark:focus {
      color: #363b43; }
  
  .ratio {
    position: relative;
    width: 100%; }
    .ratio::before {
      display: block;
      padding-top: var(--aspect-ratio);
      content: ""; }
    .ratio > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  
  .ratio-1x1 {
    --aspect-ratio: 100%; }
  
  .ratio-4x3 {
    --aspect-ratio: calc(3 / 4 * 100%); }
  
  .ratio-16x9 {
    --aspect-ratio: calc(9 / 16 * 100%); }
  
  .ratio-21x9 {
    --aspect-ratio: calc(9 / 21 * 100%); }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; }
  
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 768px) {
    .sticky-md-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  @media (min-width: 1400px) {
    .sticky-xxl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  
  .visually-hidden,
  .visually-hidden-focusable:not(:focus) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important; }
  
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""; }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  
  .align-baseline {
    vertical-align: baseline !important; }
  
  .align-top {
    vertical-align: top !important; }
  
  .align-middle {
    vertical-align: middle !important; }
  
  .align-bottom {
    vertical-align: bottom !important; }
  
  .align-text-bottom {
    vertical-align: text-bottom !important; }
  
  .align-text-top {
    vertical-align: text-top !important; }
  
  .float-start {
    float: left !important; }
  
  .float-end {
    float: right !important; }
  
  .float-none {
    float: none !important; }
  
  .overflow-auto {
    overflow: auto !important; }
  
  .overflow-hidden {
    overflow: hidden !important; }
  
  .overflow-visible {
    overflow: visible !important; }
  
  .overflow-scroll {
    overflow: scroll !important; }
  
  .d-inline {
    display: inline !important; }
  
  .d-inline-block {
    display: inline-block !important; }
  
  .d-block {
    display: block !important; }
  
  .d-grid {
    display: grid !important; }
  
  .d-table {
    display: table !important; }
  
  .d-table-row {
    display: table-row !important; }
  
  .d-table-cell {
    display: table-cell !important; }
  
  .d-flex {
    display: flex !important; }
  
  .d-inline-flex {
    display: inline-flex !important; }
  
  .d-none {
    display: none !important; }
  
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
  
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  
  .shadow-none {
    box-shadow: none !important; }
  
  .position-static {
    position: static !important; }
  
  .position-relative {
    position: relative !important; }
  
  .position-absolute {
    position: absolute !important; }
  
  .position-fixed {
    position: fixed !important; }
  
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; }
  
  .top-0 {
    top: 0 !important; }
  
  .top-50 {
    top: 50% !important; }
  
  .top-100 {
    top: 100% !important; }
  
  .bottom-0 {
    bottom: 0 !important; }
  
  .bottom-50 {
    bottom: 50% !important; }
  
  .bottom-100 {
    bottom: 100% !important; }
  
  .start-0 {
    left: 0 !important; }
  
  .start-50 {
    left: 50% !important; }
  
  .start-100 {
    left: 100% !important; }
  
  .end-0 {
    right: 0 !important; }
  
  .end-50 {
    right: 50% !important; }
  
  .end-100 {
    right: 100% !important; }
  
  .translate-middle {
    transform: translate(-50%, -50%) !important; }
  
  .translate-middle-x {
    transform: translateX(-50%) !important; }
  
  .translate-middle-y {
    transform: translateY(-50%) !important; }
  
  .border {
    border: 1px solid #dee2e6 !important; }
  
  .border-0 {
    border: 0 !important; }
  
  .border-top {
    border-top: 1px solid #dee2e6 !important; }
  
  .border-top-0 {
    border-top: 0 !important; }
  
  .border-end {
    border-right: 1px solid #dee2e6 !important; }
  
  .border-end-0 {
    border-right: 0 !important; }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important; }
  
  .border-bottom-0 {
    border-bottom: 0 !important; }
  
  .border-start {
    border-left: 1px solid #dee2e6 !important; }
  
  .border-start-0 {
    border-left: 0 !important; }
  
  .border-primary {
    border-color: #4A89DC !important; }
  
  .border-secondary {
    border-color: #656D78 !important; }
  
  .border-success {
    border-color: #37BC9B !important; }
  
  .border-info {
    border-color: #3BAFDA !important; }
  
  .border-warning {
    border-color: #F6BB42 !important; }
  
  .border-danger {
    border-color: #E9573F !important; }
  
  .border-light {
    border-color: #E6E9ED !important; }
  
  .border-dark {
    border-color: #434A54 !important; }
  
  .border-white {
    border-color: #fff !important; }
  
  .border-0 {
    border-width: 0 !important; }
  
  .border-1 {
    border-width: 1px !important; }
  
  .border-2 {
    border-width: 2px !important; }
  
  .border-3 {
    border-width: 3px !important; }
  
  .border-4 {
    border-width: 4px !important; }
  
  .border-5 {
    border-width: 5px !important; }
  
  .w-25 {
    width: 25% !important; }
  
  .w-50 {
    width: 50% !important; }
  
  .w-75 {
    width: 75% !important; }
  
  .w-100 {
    width: 100% !important; }
  
  .w-auto {
    width: auto !important; }
  
  .mw-100 {
    max-width: 100% !important; }
  
  .vw-100 {
    width: 100vw !important; }
  
  .min-vw-100 {
    min-width: 100vw !important; }
  
  .h-25 {
    height: 25% !important; }
  
  .h-50 {
    height: 50% !important; }
  
  .h-75 {
    height: 75% !important; }
  
  .h-100 {
    height: 100% !important; }
  
  .h-auto {
    height: auto !important; }
  
  .mh-100 {
    max-height: 100% !important; }
  
  .vh-100 {
    height: 100vh !important; }
  
  .min-vh-100 {
    min-height: 100vh !important; }
  
  .flex-fill {
    flex: 1 1 auto !important; }
  
  .flex-row {
    flex-direction: row !important; }
  
  .flex-column {
    flex-direction: column !important; }
  
  .flex-row-reverse {
    flex-direction: row-reverse !important; }
  
  .flex-column-reverse {
    flex-direction: column-reverse !important; }
  
  .flex-grow-0 {
    flex-grow: 0 !important; }
  
  .flex-grow-1 {
    flex-grow: 1 !important; }
  
  .flex-shrink-0 {
    flex-shrink: 0 !important; }
  
  .flex-shrink-1 {
    flex-shrink: 1 !important; }
  
  .flex-wrap {
    flex-wrap: wrap !important; }
  
  .flex-nowrap {
    flex-wrap: nowrap !important; }
  
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  
  .gap-0 {
    gap: 0 !important; }
  
  .gap-1 {
    gap: 0.5rem !important; }
  
  .gap-2 {
    gap: 1rem !important; }
  
  .gap-3 {
    gap: 2rem !important; }
  
  .gap-4 {
    gap: 3rem !important; }
  
  .gap-5 {
    gap: 6rem !important; }
  
  .justify-content-start {
    justify-content: flex-start !important; }
  
  .justify-content-end {
    justify-content: flex-end !important; }
  
  .justify-content-center {
    justify-content: center !important; }
  
  .justify-content-between {
    justify-content: space-between !important; }
  
  .justify-content-around {
    justify-content: space-around !important; }
  
  .justify-content-evenly {
    justify-content: space-evenly !important; }
  
  .align-items-start {
    align-items: flex-start !important; }
  
  .align-items-end {
    align-items: flex-end !important; }
  
  .align-items-center {
    align-items: center !important; }
  
  .align-items-baseline {
    align-items: baseline !important; }
  
  .align-items-stretch {
    align-items: stretch !important; }
  
  .align-content-start {
    align-content: flex-start !important; }
  
  .align-content-end {
    align-content: flex-end !important; }
  
  .align-content-center {
    align-content: center !important; }
  
  .align-content-between {
    align-content: space-between !important; }
  
  .align-content-around {
    align-content: space-around !important; }
  
  .align-content-stretch {
    align-content: stretch !important; }
  
  .align-self-auto {
    align-self: auto !important; }
  
  .align-self-start {
    align-self: flex-start !important; }
  
  .align-self-end {
    align-self: flex-end !important; }
  
  .align-self-center {
    align-self: center !important; }
  
  .align-self-baseline {
    align-self: baseline !important; }
  
  .align-self-stretch {
    align-self: stretch !important; }
  
  .order-first {
    order: -1 !important; }
  
  .order-0 {
    order: 0 !important; }
  
  .order-1 {
    order: 1 !important; }
  
  .order-2 {
    order: 2 !important; }
  
  .order-3 {
    order: 3 !important; }
  
  .order-4 {
    order: 4 !important; }
  
  .order-5 {
    order: 5 !important; }
  
  .order-last {
    order: 6 !important; }
  
  .m-0 {
    margin: 0 !important; }
  
  .m-1 {
    margin: 0.5rem !important; }
  
  .m-2 {
    margin: 1rem !important; }
  
  .m-3 {
    margin: 2rem !important; }
  
  .m-4 {
    margin: 3rem !important; }
  
  .m-5 {
    margin: 6rem !important; }
  
  .m-auto {
    margin: auto !important; }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  
  .mx-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  
  .mx-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  
  .mx-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  
  .mx-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  
  .mx-5 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  
  .my-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  
  .my-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  
  .my-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  
  .my-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  
  .my-5 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  
  .mt-0 {
    margin-top: 0 !important; }
  
  .mt-1 {
    margin-top: 0.5rem !important; }
  
  .mt-2 {
    margin-top: 1rem !important; }
  
  .mt-3 {
    margin-top: 2rem !important; }
  
  .mt-4 {
    margin-top: 3rem !important; }
  
  .mt-5 {
    margin-top: 6rem !important; }
  
  .mt-auto {
    margin-top: auto !important; }
  
  .me-0 {
    margin-right: 0 !important; }
  
  .me-1 {
    margin-right: 0.5rem !important; }
  
  .me-2 {
    margin-right: 1rem !important; }
  
  .me-3 {
    margin-right: 2rem !important; }
  
  .me-4 {
    margin-right: 3rem !important; }
  
  .me-5 {
    margin-right: 6rem !important; }
  
  .me-auto {
    margin-right: auto !important; }
  
  .mb-0 {
    margin-bottom: 0 !important; }
  
  .mb-1 {
    margin-bottom: 0.5rem !important; }
  
  .mb-2 {
    margin-bottom: 1rem !important; }
  
  .mb-3 {
    margin-bottom: 2rem !important; }
  
  .mb-4 {
    margin-bottom: 3rem !important; }
  
  .mb-5 {
    margin-bottom: 6rem !important; }
  
  .mb-auto {
    margin-bottom: auto !important; }
  
  .ms-0 {
    margin-left: 0 !important; }
  
  .ms-1 {
    margin-left: 0.5rem !important; }
  
  .ms-2 {
    margin-left: 1rem !important; }
  
  .ms-3 {
    margin-left: 2rem !important; }
  
  .ms-4 {
    margin-left: 3rem !important; }
  
  .ms-5 {
    margin-left: 6rem !important; }
  
  .ms-auto {
    margin-left: auto !important; }
  
  .p-0 {
    padding: 0 !important; }
  
  .p-1 {
    padding: 0.5rem !important; }
  
  .p-2 {
    padding: 1rem !important; }
  
  .p-3 {
    padding: 2rem !important; }
  
  .p-4 {
    padding: 3rem !important; }
  
  .p-5 {
    padding: 6rem !important; }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  
  .px-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  
  .px-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  
  .px-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  
  .px-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  
  .px-5 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  
  .py-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  
  .py-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  
  .py-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  
  .py-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  
  .py-5 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  
  .pt-0 {
    padding-top: 0 !important; }
  
  .pt-1 {
    padding-top: 0.5rem !important; }
  
  .pt-2 {
    padding-top: 1rem !important; }
  
  .pt-3 {
    padding-top: 2rem !important; }
  
  .pt-4 {
    padding-top: 3rem !important; }
  
  .pt-5 {
    padding-top: 6rem !important; }
  
  .pe-0 {
    padding-right: 0 !important; }
  
  .pe-1 {
    padding-right: 0.5rem !important; }
  
  .pe-2 {
    padding-right: 1rem !important; }
  
  .pe-3 {
    padding-right: 2rem !important; }
  
  .pe-4 {
    padding-right: 3rem !important; }
  
  .pe-5 {
    padding-right: 6rem !important; }
  
  .pb-0 {
    padding-bottom: 0 !important; }
  
  .pb-1 {
    padding-bottom: 0.5rem !important; }
  
  .pb-2 {
    padding-bottom: 1rem !important; }
  
  .pb-3 {
    padding-bottom: 2rem !important; }
  
  .pb-4 {
    padding-bottom: 3rem !important; }
  
  .pb-5 {
    padding-bottom: 6rem !important; }
  
  .ps-0 {
    padding-left: 0 !important; }
  
  .ps-1 {
    padding-left: 0.5rem !important; }
  
  .ps-2 {
    padding-left: 1rem !important; }
  
  .ps-3 {
    padding-left: 2rem !important; }
  
  .ps-4 {
    padding-left: 3rem !important; }
  
  .ps-5 {
    padding-left: 6rem !important; }
  
  .fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important; }
  
  .fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  
  .fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important; }
  
  .fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important; }
  
  .fs-5 {
    font-size: 1.25rem !important; }
  
  .fs-6 {
    font-size: 1rem !important; }
  
  .fst-italic {
    font-style: italic !important; }
  
  .fst-normal {
    font-style: normal !important; }
  
  .fw-light {
    font-weight: 300 !important; }
  
  .fw-lighter {
    font-weight: lighter !important; }
  
  .fw-normal {
    font-weight: 400 !important; }
  
  .fw-bold {
    font-weight: 700 !important; }
  
  .fw-bolder {
    font-weight: bolder !important; }
  
  .text-lowercase {
    text-transform: lowercase !important; }
  
  .text-uppercase {
    text-transform: uppercase !important; }
  
  .text-capitalize {
    text-transform: capitalize !important; }
  
  .text-start {
    text-align: left !important; }
  
  .text-end {
    text-align: right !important; }
  
  .text-center {
    text-align: center !important; }
  
  .text-primary {
    color: #4A89DC !important; }
  
  .text-secondary {
    color: #656D78 !important; }
  
  .text-success {
    color: #37BC9B !important; }
  
  .text-info {
    color: #3BAFDA !important; }
  
  .text-warning {
    color: #F6BB42 !important; }
  
  .text-danger {
    color: #E9573F !important; }
  
  .text-light {
    color: #E6E9ED !important; }
  
  .text-dark {
    color: #434A54 !important; }
  
  .text-white {
    color: #fff !important; }
  
  .text-body {
    color: #434A54 !important; }
  
  .text-muted {
    color: #6c757d !important; }
  
  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }
  
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }
  
  .text-reset {
    color: inherit !important; }
  
  .lh-1 {
    line-height: 1 !important; }
  
  .lh-sm {
    line-height: 1.25 !important; }
  
  .lh-base {
    line-height: 1.5 !important; }
  
  .lh-lg {
    line-height: 2 !important; }
  
  .bg-primary {
    background-color: #4A89DC !important; }
  
  .bg-secondary {
    background-color: #656D78 !important; }
  
  .bg-success {
    background-color: #37BC9B !important; }
  
  .bg-info {
    background-color: #3BAFDA !important; }
  
  .bg-warning {
    background-color: #F6BB42 !important; }
  
  .bg-danger {
    background-color: #E9573F !important; }
  
  .bg-light {
    background-color: #E6E9ED !important; }
  
  .bg-dark {
    background-color: #434A54 !important; }
  
  .bg-body {
    background-color: #F5F7FA !important; }
  
  .bg-white {
    background-color: #fff !important; }
  
  .bg-transparent {
    background-color: transparent !important; }
  
  .bg-gradient {
    background-image: var(--bs-gradient) !important; }
  
  .text-wrap {
    white-space: normal !important; }
  
  .text-nowrap {
    white-space: nowrap !important; }
  
  .text-decoration-none {
    text-decoration: none !important; }
  
  .text-decoration-underline {
    text-decoration: underline !important; }
  
  .text-decoration-line-through {
    text-decoration: line-through !important; }
  
  /* rtl:begin:remove */
  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important; }
  
  /* rtl:end:remove */
  .font-monospace {
    font-family: var(--bs-font-monospace) !important; }
  
  .user-select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important; }
  
  .user-select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important; }
  
  .user-select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important; }
  
  .pe-none {
    pointer-events: none !important; }
  
  .pe-auto {
    pointer-events: auto !important; }
  
  .rounded {
    border-radius: 0.25rem !important; }
  
  .rounded-0 {
    border-radius: 0 !important; }
  
  .rounded-1 {
    border-radius: 0.2rem !important; }
  
  .rounded-2 {
    border-radius: 0.25rem !important; }
  
  .rounded-3 {
    border-radius: 0.3rem !important; }
  
  .rounded-circle {
    border-radius: 50% !important; }
  
  .rounded-pill {
    border-radius: 50rem !important; }
  
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important; }
  
  .rounded-end {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  
  .rounded-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important; }
  
  .visible {
    visibility: visible !important; }
  
  .invisible {
    visibility: hidden !important; }
  
  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important; }
    .float-sm-end {
      float: right !important; }
    .float-sm-none {
      float: none !important; }
    .d-sm-inline {
      display: inline !important; }
    .d-sm-inline-block {
      display: inline-block !important; }
    .d-sm-block {
      display: block !important; }
    .d-sm-grid {
      display: grid !important; }
    .d-sm-table {
      display: table !important; }
    .d-sm-table-row {
      display: table-row !important; }
    .d-sm-table-cell {
      display: table-cell !important; }
    .d-sm-flex {
      display: flex !important; }
    .d-sm-inline-flex {
      display: inline-flex !important; }
    .d-sm-none {
      display: none !important; }
    .flex-sm-fill {
      flex: 1 1 auto !important; }
    .flex-sm-row {
      flex-direction: row !important; }
    .flex-sm-column {
      flex-direction: column !important; }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-sm-wrap {
      flex-wrap: wrap !important; }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-sm-0 {
      gap: 0 !important; }
    .gap-sm-1 {
      gap: 0.5rem !important; }
    .gap-sm-2 {
      gap: 1rem !important; }
    .gap-sm-3 {
      gap: 2rem !important; }
    .gap-sm-4 {
      gap: 3rem !important; }
    .gap-sm-5 {
      gap: 6rem !important; }
    .justify-content-sm-start {
      justify-content: flex-start !important; }
    .justify-content-sm-end {
      justify-content: flex-end !important; }
    .justify-content-sm-center {
      justify-content: center !important; }
    .justify-content-sm-between {
      justify-content: space-between !important; }
    .justify-content-sm-around {
      justify-content: space-around !important; }
    .justify-content-sm-evenly {
      justify-content: space-evenly !important; }
    .align-items-sm-start {
      align-items: flex-start !important; }
    .align-items-sm-end {
      align-items: flex-end !important; }
    .align-items-sm-center {
      align-items: center !important; }
    .align-items-sm-baseline {
      align-items: baseline !important; }
    .align-items-sm-stretch {
      align-items: stretch !important; }
    .align-content-sm-start {
      align-content: flex-start !important; }
    .align-content-sm-end {
      align-content: flex-end !important; }
    .align-content-sm-center {
      align-content: center !important; }
    .align-content-sm-between {
      align-content: space-between !important; }
    .align-content-sm-around {
      align-content: space-around !important; }
    .align-content-sm-stretch {
      align-content: stretch !important; }
    .align-self-sm-auto {
      align-self: auto !important; }
    .align-self-sm-start {
      align-self: flex-start !important; }
    .align-self-sm-end {
      align-self: flex-end !important; }
    .align-self-sm-center {
      align-self: center !important; }
    .align-self-sm-baseline {
      align-self: baseline !important; }
    .align-self-sm-stretch {
      align-self: stretch !important; }
    .order-sm-first {
      order: -1 !important; }
    .order-sm-0 {
      order: 0 !important; }
    .order-sm-1 {
      order: 1 !important; }
    .order-sm-2 {
      order: 2 !important; }
    .order-sm-3 {
      order: 3 !important; }
    .order-sm-4 {
      order: 4 !important; }
    .order-sm-5 {
      order: 5 !important; }
    .order-sm-last {
      order: 6 !important; }
    .m-sm-0 {
      margin: 0 !important; }
    .m-sm-1 {
      margin: 0.5rem !important; }
    .m-sm-2 {
      margin: 1rem !important; }
    .m-sm-3 {
      margin: 2rem !important; }
    .m-sm-4 {
      margin: 3rem !important; }
    .m-sm-5 {
      margin: 6rem !important; }
    .m-sm-auto {
      margin: auto !important; }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-sm-1 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-sm-2 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-sm-3 {
      margin-right: 2rem !important;
      margin-left: 2rem !important; }
    .mx-sm-4 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-sm-5 {
      margin-right: 6rem !important;
      margin-left: 6rem !important; }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-sm-1 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-sm-2 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-sm-3 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important; }
    .my-sm-4 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-sm-5 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important; }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-sm-0 {
      margin-top: 0 !important; }
    .mt-sm-1 {
      margin-top: 0.5rem !important; }
    .mt-sm-2 {
      margin-top: 1rem !important; }
    .mt-sm-3 {
      margin-top: 2rem !important; }
    .mt-sm-4 {
      margin-top: 3rem !important; }
    .mt-sm-5 {
      margin-top: 6rem !important; }
    .mt-sm-auto {
      margin-top: auto !important; }
    .me-sm-0 {
      margin-right: 0 !important; }
    .me-sm-1 {
      margin-right: 0.5rem !important; }
    .me-sm-2 {
      margin-right: 1rem !important; }
    .me-sm-3 {
      margin-right: 2rem !important; }
    .me-sm-4 {
      margin-right: 3rem !important; }
    .me-sm-5 {
      margin-right: 6rem !important; }
    .me-sm-auto {
      margin-right: auto !important; }
    .mb-sm-0 {
      margin-bottom: 0 !important; }
    .mb-sm-1 {
      margin-bottom: 0.5rem !important; }
    .mb-sm-2 {
      margin-bottom: 1rem !important; }
    .mb-sm-3 {
      margin-bottom: 2rem !important; }
    .mb-sm-4 {
      margin-bottom: 3rem !important; }
    .mb-sm-5 {
      margin-bottom: 6rem !important; }
    .mb-sm-auto {
      margin-bottom: auto !important; }
    .ms-sm-0 {
      margin-left: 0 !important; }
    .ms-sm-1 {
      margin-left: 0.5rem !important; }
    .ms-sm-2 {
      margin-left: 1rem !important; }
    .ms-sm-3 {
      margin-left: 2rem !important; }
    .ms-sm-4 {
      margin-left: 3rem !important; }
    .ms-sm-5 {
      margin-left: 6rem !important; }
    .ms-sm-auto {
      margin-left: auto !important; }
    .p-sm-0 {
      padding: 0 !important; }
    .p-sm-1 {
      padding: 0.5rem !important; }
    .p-sm-2 {
      padding: 1rem !important; }
    .p-sm-3 {
      padding: 2rem !important; }
    .p-sm-4 {
      padding: 3rem !important; }
    .p-sm-5 {
      padding: 6rem !important; }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-sm-1 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-sm-2 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-sm-3 {
      padding-right: 2rem !important;
      padding-left: 2rem !important; }
    .px-sm-4 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-sm-5 {
      padding-right: 6rem !important;
      padding-left: 6rem !important; }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-sm-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-sm-2 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-sm-3 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; }
    .py-sm-4 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-sm-5 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important; }
    .pt-sm-0 {
      padding-top: 0 !important; }
    .pt-sm-1 {
      padding-top: 0.5rem !important; }
    .pt-sm-2 {
      padding-top: 1rem !important; }
    .pt-sm-3 {
      padding-top: 2rem !important; }
    .pt-sm-4 {
      padding-top: 3rem !important; }
    .pt-sm-5 {
      padding-top: 6rem !important; }
    .pe-sm-0 {
      padding-right: 0 !important; }
    .pe-sm-1 {
      padding-right: 0.5rem !important; }
    .pe-sm-2 {
      padding-right: 1rem !important; }
    .pe-sm-3 {
      padding-right: 2rem !important; }
    .pe-sm-4 {
      padding-right: 3rem !important; }
    .pe-sm-5 {
      padding-right: 6rem !important; }
    .pb-sm-0 {
      padding-bottom: 0 !important; }
    .pb-sm-1 {
      padding-bottom: 0.5rem !important; }
    .pb-sm-2 {
      padding-bottom: 1rem !important; }
    .pb-sm-3 {
      padding-bottom: 2rem !important; }
    .pb-sm-4 {
      padding-bottom: 3rem !important; }
    .pb-sm-5 {
      padding-bottom: 6rem !important; }
    .ps-sm-0 {
      padding-left: 0 !important; }
    .ps-sm-1 {
      padding-left: 0.5rem !important; }
    .ps-sm-2 {
      padding-left: 1rem !important; }
    .ps-sm-3 {
      padding-left: 2rem !important; }
    .ps-sm-4 {
      padding-left: 3rem !important; }
    .ps-sm-5 {
      padding-left: 6rem !important; }
    .text-sm-start {
      text-align: left !important; }
    .text-sm-end {
      text-align: right !important; }
    .text-sm-center {
      text-align: center !important; } }
  
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important; }
    .float-md-end {
      float: right !important; }
    .float-md-none {
      float: none !important; }
    .d-md-inline {
      display: inline !important; }
    .d-md-inline-block {
      display: inline-block !important; }
    .d-md-block {
      display: block !important; }
    .d-md-grid {
      display: grid !important; }
    .d-md-table {
      display: table !important; }
    .d-md-table-row {
      display: table-row !important; }
    .d-md-table-cell {
      display: table-cell !important; }
    .d-md-flex {
      display: flex !important; }
    .d-md-inline-flex {
      display: inline-flex !important; }
    .d-md-none {
      display: none !important; }
    .flex-md-fill {
      flex: 1 1 auto !important; }
    .flex-md-row {
      flex-direction: row !important; }
    .flex-md-column {
      flex-direction: column !important; }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-md-grow-0 {
      flex-grow: 0 !important; }
    .flex-md-grow-1 {
      flex-grow: 1 !important; }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-md-wrap {
      flex-wrap: wrap !important; }
    .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-md-0 {
      gap: 0 !important; }
    .gap-md-1 {
      gap: 0.5rem !important; }
    .gap-md-2 {
      gap: 1rem !important; }
    .gap-md-3 {
      gap: 2rem !important; }
    .gap-md-4 {
      gap: 3rem !important; }
    .gap-md-5 {
      gap: 6rem !important; }
    .justify-content-md-start {
      justify-content: flex-start !important; }
    .justify-content-md-end {
      justify-content: flex-end !important; }
    .justify-content-md-center {
      justify-content: center !important; }
    .justify-content-md-between {
      justify-content: space-between !important; }
    .justify-content-md-around {
      justify-content: space-around !important; }
    .justify-content-md-evenly {
      justify-content: space-evenly !important; }
    .align-items-md-start {
      align-items: flex-start !important; }
    .align-items-md-end {
      align-items: flex-end !important; }
    .align-items-md-center {
      align-items: center !important; }
    .align-items-md-baseline {
      align-items: baseline !important; }
    .align-items-md-stretch {
      align-items: stretch !important; }
    .align-content-md-start {
      align-content: flex-start !important; }
    .align-content-md-end {
      align-content: flex-end !important; }
    .align-content-md-center {
      align-content: center !important; }
    .align-content-md-between {
      align-content: space-between !important; }
    .align-content-md-around {
      align-content: space-around !important; }
    .align-content-md-stretch {
      align-content: stretch !important; }
    .align-self-md-auto {
      align-self: auto !important; }
    .align-self-md-start {
      align-self: flex-start !important; }
    .align-self-md-end {
      align-self: flex-end !important; }
    .align-self-md-center {
      align-self: center !important; }
    .align-self-md-baseline {
      align-self: baseline !important; }
    .align-self-md-stretch {
      align-self: stretch !important; }
    .order-md-first {
      order: -1 !important; }
    .order-md-0 {
      order: 0 !important; }
    .order-md-1 {
      order: 1 !important; }
    .order-md-2 {
      order: 2 !important; }
    .order-md-3 {
      order: 3 !important; }
    .order-md-4 {
      order: 4 !important; }
    .order-md-5 {
      order: 5 !important; }
    .order-md-last {
      order: 6 !important; }
    .m-md-0 {
      margin: 0 !important; }
    .m-md-1 {
      margin: 0.5rem !important; }
    .m-md-2 {
      margin: 1rem !important; }
    .m-md-3 {
      margin: 2rem !important; }
    .m-md-4 {
      margin: 3rem !important; }
    .m-md-5 {
      margin: 6rem !important; }
    .m-md-auto {
      margin: auto !important; }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-md-1 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-md-2 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-md-3 {
      margin-right: 2rem !important;
      margin-left: 2rem !important; }
    .mx-md-4 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-md-5 {
      margin-right: 6rem !important;
      margin-left: 6rem !important; }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-md-1 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-md-2 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-md-3 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important; }
    .my-md-4 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-md-5 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important; }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-md-0 {
      margin-top: 0 !important; }
    .mt-md-1 {
      margin-top: 0.5rem !important; }
    .mt-md-2 {
      margin-top: 1rem !important; }
    .mt-md-3 {
      margin-top: 2rem !important; }
    .mt-md-4 {
      margin-top: 3rem !important; }
    .mt-md-5 {
      margin-top: 6rem !important; }
    .mt-md-auto {
      margin-top: auto !important; }
    .me-md-0 {
      margin-right: 0 !important; }
    .me-md-1 {
      margin-right: 0.5rem !important; }
    .me-md-2 {
      margin-right: 1rem !important; }
    .me-md-3 {
      margin-right: 2rem !important; }
    .me-md-4 {
      margin-right: 3rem !important; }
    .me-md-5 {
      margin-right: 6rem !important; }
    .me-md-auto {
      margin-right: auto !important; }
    .mb-md-0 {
      margin-bottom: 0 !important; }
    .mb-md-1 {
      margin-bottom: 0.5rem !important; }
    .mb-md-2 {
      margin-bottom: 1rem !important; }
    .mb-md-3 {
      margin-bottom: 2rem !important; }
    .mb-md-4 {
      margin-bottom: 3rem !important; }
    .mb-md-5 {
      margin-bottom: 6rem !important; }
    .mb-md-auto {
      margin-bottom: auto !important; }
    .ms-md-0 {
      margin-left: 0 !important; }
    .ms-md-1 {
      margin-left: 0.5rem !important; }
    .ms-md-2 {
      margin-left: 1rem !important; }
    .ms-md-3 {
      margin-left: 2rem !important; }
    .ms-md-4 {
      margin-left: 3rem !important; }
    .ms-md-5 {
      margin-left: 6rem !important; }
    .ms-md-auto {
      margin-left: auto !important; }
    .p-md-0 {
      padding: 0 !important; }
    .p-md-1 {
      padding: 0.5rem !important; }
    .p-md-2 {
      padding: 1rem !important; }
    .p-md-3 {
      padding: 2rem !important; }
    .p-md-4 {
      padding: 3rem !important; }
    .p-md-5 {
      padding: 6rem !important; }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-md-1 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-md-2 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-md-3 {
      padding-right: 2rem !important;
      padding-left: 2rem !important; }
    .px-md-4 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-md-5 {
      padding-right: 6rem !important;
      padding-left: 6rem !important; }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-md-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-md-2 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-md-3 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; }
    .py-md-4 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-md-5 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important; }
    .pt-md-0 {
      padding-top: 0 !important; }
    .pt-md-1 {
      padding-top: 0.5rem !important; }
    .pt-md-2 {
      padding-top: 1rem !important; }
    .pt-md-3 {
      padding-top: 2rem !important; }
    .pt-md-4 {
      padding-top: 3rem !important; }
    .pt-md-5 {
      padding-top: 6rem !important; }
    .pe-md-0 {
      padding-right: 0 !important; }
    .pe-md-1 {
      padding-right: 0.5rem !important; }
    .pe-md-2 {
      padding-right: 1rem !important; }
    .pe-md-3 {
      padding-right: 2rem !important; }
    .pe-md-4 {
      padding-right: 3rem !important; }
    .pe-md-5 {
      padding-right: 6rem !important; }
    .pb-md-0 {
      padding-bottom: 0 !important; }
    .pb-md-1 {
      padding-bottom: 0.5rem !important; }
    .pb-md-2 {
      padding-bottom: 1rem !important; }
    .pb-md-3 {
      padding-bottom: 2rem !important; }
    .pb-md-4 {
      padding-bottom: 3rem !important; }
    .pb-md-5 {
      padding-bottom: 6rem !important; }
    .ps-md-0 {
      padding-left: 0 !important; }
    .ps-md-1 {
      padding-left: 0.5rem !important; }
    .ps-md-2 {
      padding-left: 1rem !important; }
    .ps-md-3 {
      padding-left: 2rem !important; }
    .ps-md-4 {
      padding-left: 3rem !important; }
    .ps-md-5 {
      padding-left: 6rem !important; }
    .text-md-start {
      text-align: left !important; }
    .text-md-end {
      text-align: right !important; }
    .text-md-center {
      text-align: center !important; } }
  
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important; }
    .float-lg-end {
      float: right !important; }
    .float-lg-none {
      float: none !important; }
    .d-lg-inline {
      display: inline !important; }
    .d-lg-inline-block {
      display: inline-block !important; }
    .d-lg-block {
      display: block !important; }
    .d-lg-grid {
      display: grid !important; }
    .d-lg-table {
      display: table !important; }
    .d-lg-table-row {
      display: table-row !important; }
    .d-lg-table-cell {
      display: table-cell !important; }
    .d-lg-flex {
      display: flex !important; }
    .d-lg-inline-flex {
      display: inline-flex !important; }
    .d-lg-none {
      display: none !important; }
    .flex-lg-fill {
      flex: 1 1 auto !important; }
    .flex-lg-row {
      flex-direction: row !important; }
    .flex-lg-column {
      flex-direction: column !important; }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-lg-wrap {
      flex-wrap: wrap !important; }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-lg-0 {
      gap: 0 !important; }
    .gap-lg-1 {
      gap: 0.5rem !important; }
    .gap-lg-2 {
      gap: 1rem !important; }
    .gap-lg-3 {
      gap: 2rem !important; }
    .gap-lg-4 {
      gap: 3rem !important; }
    .gap-lg-5 {
      gap: 6rem !important; }
    .justify-content-lg-start {
      justify-content: flex-start !important; }
    .justify-content-lg-end {
      justify-content: flex-end !important; }
    .justify-content-lg-center {
      justify-content: center !important; }
    .justify-content-lg-between {
      justify-content: space-between !important; }
    .justify-content-lg-around {
      justify-content: space-around !important; }
    .justify-content-lg-evenly {
      justify-content: space-evenly !important; }
    .align-items-lg-start {
      align-items: flex-start !important; }
    .align-items-lg-end {
      align-items: flex-end !important; }
    .align-items-lg-center {
      align-items: center !important; }
    .align-items-lg-baseline {
      align-items: baseline !important; }
    .align-items-lg-stretch {
      align-items: stretch !important; }
    .align-content-lg-start {
      align-content: flex-start !important; }
    .align-content-lg-end {
      align-content: flex-end !important; }
    .align-content-lg-center {
      align-content: center !important; }
    .align-content-lg-between {
      align-content: space-between !important; }
    .align-content-lg-around {
      align-content: space-around !important; }
    .align-content-lg-stretch {
      align-content: stretch !important; }
    .align-self-lg-auto {
      align-self: auto !important; }
    .align-self-lg-start {
      align-self: flex-start !important; }
    .align-self-lg-end {
      align-self: flex-end !important; }
    .align-self-lg-center {
      align-self: center !important; }
    .align-self-lg-baseline {
      align-self: baseline !important; }
    .align-self-lg-stretch {
      align-self: stretch !important; }
    .order-lg-first {
      order: -1 !important; }
    .order-lg-0 {
      order: 0 !important; }
    .order-lg-1 {
      order: 1 !important; }
    .order-lg-2 {
      order: 2 !important; }
    .order-lg-3 {
      order: 3 !important; }
    .order-lg-4 {
      order: 4 !important; }
    .order-lg-5 {
      order: 5 !important; }
    .order-lg-last {
      order: 6 !important; }
    .m-lg-0 {
      margin: 0 !important; }
    .m-lg-1 {
      margin: 0.5rem !important; }
    .m-lg-2 {
      margin: 1rem !important; }
    .m-lg-3 {
      margin: 2rem !important; }
    .m-lg-4 {
      margin: 3rem !important; }
    .m-lg-5 {
      margin: 6rem !important; }
    .m-lg-auto {
      margin: auto !important; }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-lg-1 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-lg-2 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-lg-3 {
      margin-right: 2rem !important;
      margin-left: 2rem !important; }
    .mx-lg-4 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-lg-5 {
      margin-right: 6rem !important;
      margin-left: 6rem !important; }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-lg-1 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-lg-2 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-lg-3 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important; }
    .my-lg-4 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-lg-5 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important; }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-lg-0 {
      margin-top: 0 !important; }
    .mt-lg-1 {
      margin-top: 0.5rem !important; }
    .mt-lg-2 {
      margin-top: 1rem !important; }
    .mt-lg-3 {
      margin-top: 2rem !important; }
    .mt-lg-4 {
      margin-top: 3rem !important; }
    .mt-lg-5 {
      margin-top: 6rem !important; }
    .mt-lg-auto {
      margin-top: auto !important; }
    .me-lg-0 {
      margin-right: 0 !important; }
    .me-lg-1 {
      margin-right: 0.5rem !important; }
    .me-lg-2 {
      margin-right: 1rem !important; }
    .me-lg-3 {
      margin-right: 2rem !important; }
    .me-lg-4 {
      margin-right: 3rem !important; }
    .me-lg-5 {
      margin-right: 6rem !important; }
    .me-lg-auto {
      margin-right: auto !important; }
    .mb-lg-0 {
      margin-bottom: 0 !important; }
    .mb-lg-1 {
      margin-bottom: 0.5rem !important; }
    .mb-lg-2 {
      margin-bottom: 1rem !important; }
    .mb-lg-3 {
      margin-bottom: 2rem !important; }
    .mb-lg-4 {
      margin-bottom: 3rem !important; }
    .mb-lg-5 {
      margin-bottom: 6rem !important; }
    .mb-lg-auto {
      margin-bottom: auto !important; }
    .ms-lg-0 {
      margin-left: 0 !important; }
    .ms-lg-1 {
      margin-left: 0.5rem !important; }
    .ms-lg-2 {
      margin-left: 1rem !important; }
    .ms-lg-3 {
      margin-left: 2rem !important; }
    .ms-lg-4 {
      margin-left: 3rem !important; }
    .ms-lg-5 {
      margin-left: 6rem !important; }
    .ms-lg-auto {
      margin-left: auto !important; }
    .p-lg-0 {
      padding: 0 !important; }
    .p-lg-1 {
      padding: 0.5rem !important; }
    .p-lg-2 {
      padding: 1rem !important; }
    .p-lg-3 {
      padding: 2rem !important; }
    .p-lg-4 {
      padding: 3rem !important; }
    .p-lg-5 {
      padding: 6rem !important; }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-lg-1 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-lg-2 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-lg-3 {
      padding-right: 2rem !important;
      padding-left: 2rem !important; }
    .px-lg-4 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-lg-5 {
      padding-right: 6rem !important;
      padding-left: 6rem !important; }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-lg-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-lg-2 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-lg-3 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; }
    .py-lg-4 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-lg-5 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important; }
    .pt-lg-0 {
      padding-top: 0 !important; }
    .pt-lg-1 {
      padding-top: 0.5rem !important; }
    .pt-lg-2 {
      padding-top: 1rem !important; }
    .pt-lg-3 {
      padding-top: 2rem !important; }
    .pt-lg-4 {
      padding-top: 3rem !important; }
    .pt-lg-5 {
      padding-top: 6rem !important; }
    .pe-lg-0 {
      padding-right: 0 !important; }
    .pe-lg-1 {
      padding-right: 0.5rem !important; }
    .pe-lg-2 {
      padding-right: 1rem !important; }
    .pe-lg-3 {
      padding-right: 2rem !important; }
    .pe-lg-4 {
      padding-right: 3rem !important; }
    .pe-lg-5 {
      padding-right: 6rem !important; }
    .pb-lg-0 {
      padding-bottom: 0 !important; }
    .pb-lg-1 {
      padding-bottom: 0.5rem !important; }
    .pb-lg-2 {
      padding-bottom: 1rem !important; }
    .pb-lg-3 {
      padding-bottom: 2rem !important; }
    .pb-lg-4 {
      padding-bottom: 3rem !important; }
    .pb-lg-5 {
      padding-bottom: 6rem !important; }
    .ps-lg-0 {
      padding-left: 0 !important; }
    .ps-lg-1 {
      padding-left: 0.5rem !important; }
    .ps-lg-2 {
      padding-left: 1rem !important; }
    .ps-lg-3 {
      padding-left: 2rem !important; }
    .ps-lg-4 {
      padding-left: 3rem !important; }
    .ps-lg-5 {
      padding-left: 6rem !important; }
    .text-lg-start {
      text-align: left !important; }
    .text-lg-end {
      text-align: right !important; }
    .text-lg-center {
      text-align: center !important; } }
  
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important; }
    .float-xl-end {
      float: right !important; }
    .float-xl-none {
      float: none !important; }
    .d-xl-inline {
      display: inline !important; }
    .d-xl-inline-block {
      display: inline-block !important; }
    .d-xl-block {
      display: block !important; }
    .d-xl-grid {
      display: grid !important; }
    .d-xl-table {
      display: table !important; }
    .d-xl-table-row {
      display: table-row !important; }
    .d-xl-table-cell {
      display: table-cell !important; }
    .d-xl-flex {
      display: flex !important; }
    .d-xl-inline-flex {
      display: inline-flex !important; }
    .d-xl-none {
      display: none !important; }
    .flex-xl-fill {
      flex: 1 1 auto !important; }
    .flex-xl-row {
      flex-direction: row !important; }
    .flex-xl-column {
      flex-direction: column !important; }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-xl-wrap {
      flex-wrap: wrap !important; }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-xl-0 {
      gap: 0 !important; }
    .gap-xl-1 {
      gap: 0.5rem !important; }
    .gap-xl-2 {
      gap: 1rem !important; }
    .gap-xl-3 {
      gap: 2rem !important; }
    .gap-xl-4 {
      gap: 3rem !important; }
    .gap-xl-5 {
      gap: 6rem !important; }
    .justify-content-xl-start {
      justify-content: flex-start !important; }
    .justify-content-xl-end {
      justify-content: flex-end !important; }
    .justify-content-xl-center {
      justify-content: center !important; }
    .justify-content-xl-between {
      justify-content: space-between !important; }
    .justify-content-xl-around {
      justify-content: space-around !important; }
    .justify-content-xl-evenly {
      justify-content: space-evenly !important; }
    .align-items-xl-start {
      align-items: flex-start !important; }
    .align-items-xl-end {
      align-items: flex-end !important; }
    .align-items-xl-center {
      align-items: center !important; }
    .align-items-xl-baseline {
      align-items: baseline !important; }
    .align-items-xl-stretch {
      align-items: stretch !important; }
    .align-content-xl-start {
      align-content: flex-start !important; }
    .align-content-xl-end {
      align-content: flex-end !important; }
    .align-content-xl-center {
      align-content: center !important; }
    .align-content-xl-between {
      align-content: space-between !important; }
    .align-content-xl-around {
      align-content: space-around !important; }
    .align-content-xl-stretch {
      align-content: stretch !important; }
    .align-self-xl-auto {
      align-self: auto !important; }
    .align-self-xl-start {
      align-self: flex-start !important; }
    .align-self-xl-end {
      align-self: flex-end !important; }
    .align-self-xl-center {
      align-self: center !important; }
    .align-self-xl-baseline {
      align-self: baseline !important; }
    .align-self-xl-stretch {
      align-self: stretch !important; }
    .order-xl-first {
      order: -1 !important; }
    .order-xl-0 {
      order: 0 !important; }
    .order-xl-1 {
      order: 1 !important; }
    .order-xl-2 {
      order: 2 !important; }
    .order-xl-3 {
      order: 3 !important; }
    .order-xl-4 {
      order: 4 !important; }
    .order-xl-5 {
      order: 5 !important; }
    .order-xl-last {
      order: 6 !important; }
    .m-xl-0 {
      margin: 0 !important; }
    .m-xl-1 {
      margin: 0.5rem !important; }
    .m-xl-2 {
      margin: 1rem !important; }
    .m-xl-3 {
      margin: 2rem !important; }
    .m-xl-4 {
      margin: 3rem !important; }
    .m-xl-5 {
      margin: 6rem !important; }
    .m-xl-auto {
      margin: auto !important; }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-xl-1 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-xl-2 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-xl-3 {
      margin-right: 2rem !important;
      margin-left: 2rem !important; }
    .mx-xl-4 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-xl-5 {
      margin-right: 6rem !important;
      margin-left: 6rem !important; }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-xl-1 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-xl-2 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-xl-3 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important; }
    .my-xl-4 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-xl-5 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important; }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-xl-0 {
      margin-top: 0 !important; }
    .mt-xl-1 {
      margin-top: 0.5rem !important; }
    .mt-xl-2 {
      margin-top: 1rem !important; }
    .mt-xl-3 {
      margin-top: 2rem !important; }
    .mt-xl-4 {
      margin-top: 3rem !important; }
    .mt-xl-5 {
      margin-top: 6rem !important; }
    .mt-xl-auto {
      margin-top: auto !important; }
    .me-xl-0 {
      margin-right: 0 !important; }
    .me-xl-1 {
      margin-right: 0.5rem !important; }
    .me-xl-2 {
      margin-right: 1rem !important; }
    .me-xl-3 {
      margin-right: 2rem !important; }
    .me-xl-4 {
      margin-right: 3rem !important; }
    .me-xl-5 {
      margin-right: 6rem !important; }
    .me-xl-auto {
      margin-right: auto !important; }
    .mb-xl-0 {
      margin-bottom: 0 !important; }
    .mb-xl-1 {
      margin-bottom: 0.5rem !important; }
    .mb-xl-2 {
      margin-bottom: 1rem !important; }
    .mb-xl-3 {
      margin-bottom: 2rem !important; }
    .mb-xl-4 {
      margin-bottom: 3rem !important; }
    .mb-xl-5 {
      margin-bottom: 6rem !important; }
    .mb-xl-auto {
      margin-bottom: auto !important; }
    .ms-xl-0 {
      margin-left: 0 !important; }
    .ms-xl-1 {
      margin-left: 0.5rem !important; }
    .ms-xl-2 {
      margin-left: 1rem !important; }
    .ms-xl-3 {
      margin-left: 2rem !important; }
    .ms-xl-4 {
      margin-left: 3rem !important; }
    .ms-xl-5 {
      margin-left: 6rem !important; }
    .ms-xl-auto {
      margin-left: auto !important; }
    .p-xl-0 {
      padding: 0 !important; }
    .p-xl-1 {
      padding: 0.5rem !important; }
    .p-xl-2 {
      padding: 1rem !important; }
    .p-xl-3 {
      padding: 2rem !important; }
    .p-xl-4 {
      padding: 3rem !important; }
    .p-xl-5 {
      padding: 6rem !important; }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-xl-1 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-xl-2 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-xl-3 {
      padding-right: 2rem !important;
      padding-left: 2rem !important; }
    .px-xl-4 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-xl-5 {
      padding-right: 6rem !important;
      padding-left: 6rem !important; }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-xl-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-xl-2 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-xl-3 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; }
    .py-xl-4 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-xl-5 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important; }
    .pt-xl-0 {
      padding-top: 0 !important; }
    .pt-xl-1 {
      padding-top: 0.5rem !important; }
    .pt-xl-2 {
      padding-top: 1rem !important; }
    .pt-xl-3 {
      padding-top: 2rem !important; }
    .pt-xl-4 {
      padding-top: 3rem !important; }
    .pt-xl-5 {
      padding-top: 6rem !important; }
    .pe-xl-0 {
      padding-right: 0 !important; }
    .pe-xl-1 {
      padding-right: 0.5rem !important; }
    .pe-xl-2 {
      padding-right: 1rem !important; }
    .pe-xl-3 {
      padding-right: 2rem !important; }
    .pe-xl-4 {
      padding-right: 3rem !important; }
    .pe-xl-5 {
      padding-right: 6rem !important; }
    .pb-xl-0 {
      padding-bottom: 0 !important; }
    .pb-xl-1 {
      padding-bottom: 0.5rem !important; }
    .pb-xl-2 {
      padding-bottom: 1rem !important; }
    .pb-xl-3 {
      padding-bottom: 2rem !important; }
    .pb-xl-4 {
      padding-bottom: 3rem !important; }
    .pb-xl-5 {
      padding-bottom: 6rem !important; }
    .ps-xl-0 {
      padding-left: 0 !important; }
    .ps-xl-1 {
      padding-left: 0.5rem !important; }
    .ps-xl-2 {
      padding-left: 1rem !important; }
    .ps-xl-3 {
      padding-left: 2rem !important; }
    .ps-xl-4 {
      padding-left: 3rem !important; }
    .ps-xl-5 {
      padding-left: 6rem !important; }
    .text-xl-start {
      text-align: left !important; }
    .text-xl-end {
      text-align: right !important; }
    .text-xl-center {
      text-align: center !important; } }
  
  @media (min-width: 1400px) {
    .float-xxl-start {
      float: left !important; }
    .float-xxl-end {
      float: right !important; }
    .float-xxl-none {
      float: none !important; }
    .d-xxl-inline {
      display: inline !important; }
    .d-xxl-inline-block {
      display: inline-block !important; }
    .d-xxl-block {
      display: block !important; }
    .d-xxl-grid {
      display: grid !important; }
    .d-xxl-table {
      display: table !important; }
    .d-xxl-table-row {
      display: table-row !important; }
    .d-xxl-table-cell {
      display: table-cell !important; }
    .d-xxl-flex {
      display: flex !important; }
    .d-xxl-inline-flex {
      display: inline-flex !important; }
    .d-xxl-none {
      display: none !important; }
    .flex-xxl-fill {
      flex: 1 1 auto !important; }
    .flex-xxl-row {
      flex-direction: row !important; }
    .flex-xxl-column {
      flex-direction: column !important; }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important; }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important; }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important; }
    .flex-xxl-wrap {
      flex-wrap: wrap !important; }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important; }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .gap-xxl-0 {
      gap: 0 !important; }
    .gap-xxl-1 {
      gap: 0.5rem !important; }
    .gap-xxl-2 {
      gap: 1rem !important; }
    .gap-xxl-3 {
      gap: 2rem !important; }
    .gap-xxl-4 {
      gap: 3rem !important; }
    .gap-xxl-5 {
      gap: 6rem !important; }
    .justify-content-xxl-start {
      justify-content: flex-start !important; }
    .justify-content-xxl-end {
      justify-content: flex-end !important; }
    .justify-content-xxl-center {
      justify-content: center !important; }
    .justify-content-xxl-between {
      justify-content: space-between !important; }
    .justify-content-xxl-around {
      justify-content: space-around !important; }
    .justify-content-xxl-evenly {
      justify-content: space-evenly !important; }
    .align-items-xxl-start {
      align-items: flex-start !important; }
    .align-items-xxl-end {
      align-items: flex-end !important; }
    .align-items-xxl-center {
      align-items: center !important; }
    .align-items-xxl-baseline {
      align-items: baseline !important; }
    .align-items-xxl-stretch {
      align-items: stretch !important; }
    .align-content-xxl-start {
      align-content: flex-start !important; }
    .align-content-xxl-end {
      align-content: flex-end !important; }
    .align-content-xxl-center {
      align-content: center !important; }
    .align-content-xxl-between {
      align-content: space-between !important; }
    .align-content-xxl-around {
      align-content: space-around !important; }
    .align-content-xxl-stretch {
      align-content: stretch !important; }
    .align-self-xxl-auto {
      align-self: auto !important; }
    .align-self-xxl-start {
      align-self: flex-start !important; }
    .align-self-xxl-end {
      align-self: flex-end !important; }
    .align-self-xxl-center {
      align-self: center !important; }
    .align-self-xxl-baseline {
      align-self: baseline !important; }
    .align-self-xxl-stretch {
      align-self: stretch !important; }
    .order-xxl-first {
      order: -1 !important; }
    .order-xxl-0 {
      order: 0 !important; }
    .order-xxl-1 {
      order: 1 !important; }
    .order-xxl-2 {
      order: 2 !important; }
    .order-xxl-3 {
      order: 3 !important; }
    .order-xxl-4 {
      order: 4 !important; }
    .order-xxl-5 {
      order: 5 !important; }
    .order-xxl-last {
      order: 6 !important; }
    .m-xxl-0 {
      margin: 0 !important; }
    .m-xxl-1 {
      margin: 0.5rem !important; }
    .m-xxl-2 {
      margin: 1rem !important; }
    .m-xxl-3 {
      margin: 2rem !important; }
    .m-xxl-4 {
      margin: 3rem !important; }
    .m-xxl-5 {
      margin: 6rem !important; }
    .m-xxl-auto {
      margin: auto !important; }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .mx-xxl-1 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .mx-xxl-2 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .mx-xxl-3 {
      margin-right: 2rem !important;
      margin-left: 2rem !important; }
    .mx-xxl-4 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .mx-xxl-5 {
      margin-right: 6rem !important;
      margin-left: 6rem !important; }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .my-xxl-1 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .my-xxl-2 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .my-xxl-3 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important; }
    .my-xxl-4 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .my-xxl-5 {
      margin-top: 6rem !important;
      margin-bottom: 6rem !important; }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .mt-xxl-0 {
      margin-top: 0 !important; }
    .mt-xxl-1 {
      margin-top: 0.5rem !important; }
    .mt-xxl-2 {
      margin-top: 1rem !important; }
    .mt-xxl-3 {
      margin-top: 2rem !important; }
    .mt-xxl-4 {
      margin-top: 3rem !important; }
    .mt-xxl-5 {
      margin-top: 6rem !important; }
    .mt-xxl-auto {
      margin-top: auto !important; }
    .me-xxl-0 {
      margin-right: 0 !important; }
    .me-xxl-1 {
      margin-right: 0.5rem !important; }
    .me-xxl-2 {
      margin-right: 1rem !important; }
    .me-xxl-3 {
      margin-right: 2rem !important; }
    .me-xxl-4 {
      margin-right: 3rem !important; }
    .me-xxl-5 {
      margin-right: 6rem !important; }
    .me-xxl-auto {
      margin-right: auto !important; }
    .mb-xxl-0 {
      margin-bottom: 0 !important; }
    .mb-xxl-1 {
      margin-bottom: 0.5rem !important; }
    .mb-xxl-2 {
      margin-bottom: 1rem !important; }
    .mb-xxl-3 {
      margin-bottom: 2rem !important; }
    .mb-xxl-4 {
      margin-bottom: 3rem !important; }
    .mb-xxl-5 {
      margin-bottom: 6rem !important; }
    .mb-xxl-auto {
      margin-bottom: auto !important; }
    .ms-xxl-0 {
      margin-left: 0 !important; }
    .ms-xxl-1 {
      margin-left: 0.5rem !important; }
    .ms-xxl-2 {
      margin-left: 1rem !important; }
    .ms-xxl-3 {
      margin-left: 2rem !important; }
    .ms-xxl-4 {
      margin-left: 3rem !important; }
    .ms-xxl-5 {
      margin-left: 6rem !important; }
    .ms-xxl-auto {
      margin-left: auto !important; }
    .p-xxl-0 {
      padding: 0 !important; }
    .p-xxl-1 {
      padding: 0.5rem !important; }
    .p-xxl-2 {
      padding: 1rem !important; }
    .p-xxl-3 {
      padding: 2rem !important; }
    .p-xxl-4 {
      padding: 3rem !important; }
    .p-xxl-5 {
      padding: 6rem !important; }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .px-xxl-1 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .px-xxl-2 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .px-xxl-3 {
      padding-right: 2rem !important;
      padding-left: 2rem !important; }
    .px-xxl-4 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .px-xxl-5 {
      padding-right: 6rem !important;
      padding-left: 6rem !important; }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .py-xxl-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .py-xxl-2 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .py-xxl-3 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; }
    .py-xxl-4 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .py-xxl-5 {
      padding-top: 6rem !important;
      padding-bottom: 6rem !important; }
    .pt-xxl-0 {
      padding-top: 0 !important; }
    .pt-xxl-1 {
      padding-top: 0.5rem !important; }
    .pt-xxl-2 {
      padding-top: 1rem !important; }
    .pt-xxl-3 {
      padding-top: 2rem !important; }
    .pt-xxl-4 {
      padding-top: 3rem !important; }
    .pt-xxl-5 {
      padding-top: 6rem !important; }
    .pe-xxl-0 {
      padding-right: 0 !important; }
    .pe-xxl-1 {
      padding-right: 0.5rem !important; }
    .pe-xxl-2 {
      padding-right: 1rem !important; }
    .pe-xxl-3 {
      padding-right: 2rem !important; }
    .pe-xxl-4 {
      padding-right: 3rem !important; }
    .pe-xxl-5 {
      padding-right: 6rem !important; }
    .pb-xxl-0 {
      padding-bottom: 0 !important; }
    .pb-xxl-1 {
      padding-bottom: 0.5rem !important; }
    .pb-xxl-2 {
      padding-bottom: 1rem !important; }
    .pb-xxl-3 {
      padding-bottom: 2rem !important; }
    .pb-xxl-4 {
      padding-bottom: 3rem !important; }
    .pb-xxl-5 {
      padding-bottom: 6rem !important; }
    .ps-xxl-0 {
      padding-left: 0 !important; }
    .ps-xxl-1 {
      padding-left: 0.5rem !important; }
    .ps-xxl-2 {
      padding-left: 1rem !important; }
    .ps-xxl-3 {
      padding-left: 2rem !important; }
    .ps-xxl-4 {
      padding-left: 3rem !important; }
    .ps-xxl-5 {
      padding-left: 6rem !important; }
    .text-xxl-start {
      text-align: left !important; }
    .text-xxl-end {
      text-align: right !important; }
    .text-xxl-center {
      text-align: center !important; } }
  
  @media (min-width: 1200px) {
    .fs-1 {
      font-size: 2.5rem !important; }
    .fs-2 {
      font-size: 2rem !important; }
    .fs-3 {
      font-size: 1.75rem !important; }
    .fs-4 {
      font-size: 1.5rem !important; }
    .fs-sm-1 {
      font-size: 2.5rem !important; }
    .fs-sm-2 {
      font-size: 2rem !important; }
    .fs-sm-3 {
      font-size: 1.75rem !important; }
    .fs-sm-4 {
      font-size: 1.5rem !important; }
    .fs-md-1 {
      font-size: 2.5rem !important; }
    .fs-md-2 {
      font-size: 2rem !important; }
    .fs-md-3 {
      font-size: 1.75rem !important; }
    .fs-md-4 {
      font-size: 1.5rem !important; }
    .fs-lg-1 {
      font-size: 2.5rem !important; }
    .fs-lg-2 {
      font-size: 2rem !important; }
    .fs-lg-3 {
      font-size: 1.75rem !important; }
    .fs-lg-4 {
      font-size: 1.5rem !important; } }
  
  @media print {
    .d-print-inline {
      display: inline !important; }
    .d-print-inline-block {
      display: inline-block !important; }
    .d-print-block {
      display: block !important; }
    .d-print-grid {
      display: grid !important; }
    .d-print-table {
      display: table !important; }
    .d-print-table-row {
      display: table-row !important; }
    .d-print-table-cell {
      display: table-cell !important; }
    .d-print-flex {
      display: flex !important; }
    .d-print-inline-flex {
      display: inline-flex !important; }
    .d-print-none {
      display: none !important; } }
  
  /* Bootstrap default style overrides */
  .btn {
    font-size: 0.9375rem; }
  
  .btn:focus, .btn:hover {
    transform: translateY(-2px); }
  
  hr {
    border-color: #E6E9ED; }
  
  .text-small {
    font-size: 0.875rem; }
  
  .hover-effect {
    transition: transform .18s ease-in-out; }
  
  .hover-effect:hover {
    transform: translateY(-2px); }
  
    @media print {
        body {
          background-color: #fff; }
        .container {
          width: auto;
          max-width: 100%;
          padding: 0; }
        .cover, .cover-bg {
          border-radius: 0; }
        .cover.shadow-lg {
          box-shadow: none !important; }
        .cover-bg {
          padding: 5rem !important;
          padding-bottom: 10px !important; }
        .avatar {
          margin-top: -10px; }
        .about-section {
          padding: 6.5rem 5rem 2rem !important; }
        .skills-section,
        .work-experience-section,
        .education-section,
        .contant-section {
          padding: 1.5rem 5rem 2rem !important; }
        .page-break {
          padding-top: 5rem;
          page-break-before: always; } }

          /* Buttons */
/* Inputs */
/* Timeline custom component */
.timeline {
  border-left: 2px solid #E6E9ED;
  padding: 1rem 0; }

.timeline-card {
  position: relative;
  margin-left: 31px;
  border-left: 2px solid;
  margin-bottom: 2rem; }

.timeline-card:last-child {
  margin-bottom: 1rem; }

.timeline-card:before {
  content: '';
  display: inline-block;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  top: 20px;
  left: -41px;
  border: 2px solid;
  z-index: 2; }

.timeline-card:after {
  content: '';
  display: inline-block;
  position: absolute;
  background-color: currentColor;
  width: 29px;
  height: 2px;
  top: 25px;
  left: -29px;
  z-index: 1; }

.timeline-card-primary {
  border-left-color: #4A89DC; }

.timeline-card-primary:before {
  border-color: #4A89DC; }

.timeline-card-primary:after {
  background-color: #4A89DC; }

.timeline-card-success {
  border-left-color: #37BC9B; }

.timeline-card-success:before {
  border-color: #37BC9B; }

.timeline-card-success:after {
  background-color: #37BC9B; }

html {
  scroll-behavior: smooth; }

.site-title {
  font-size: 1.25rem;
  line-height: 2.5rem; }

.nav-link {
  padding: 0;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: rgba(0, 0, 0, 0.5); }

.nav-link:hover,
.nav-link:focus,
.active .nav-link {
  color: rgba(0, 0, 0, 0.8); }

.nav-item + .nav-item {
  margin-left: 1rem; }

.cover {
  border-radius: 10px; }

.cover-bg {
  background-color: #4A89DC;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.12'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  border-radius: 10px 10px 0 0; }

.avatar {
  max-width: 216px;
  max-height: 216px;
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.avatar img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

footer a:not(.nav-link) {
  color: inherit;
  border-bottom: 1px dashed;
  text-decoration: none;
  cursor: pointer; }

@media (min-width: 48em) {
  .site-title {
    float: left; }
  .site-nav {
    float: right; }
  .avatar {
    margin-bottom: -80px;
    margin-left: 0; } }

@media print {
  body {
    background-color: #fff; }
  .container {
    width: auto;
    max-width: 100%;
    padding: 0; }
  .cover, .cover-bg {
    border-radius: 0; }
  .cover.shadow-lg {
    box-shadow: none !important; }
  .cover-bg {
    padding: 5rem !important;
    padding-bottom: 10px !important; }
  .avatar {
    margin-top: -10px; }
  .about-section {
    padding: 6.5rem 5rem 2rem !important; }
  .skills-section,
  .work-experience-section,
  .education-section,
  .contant-section {
    padding: 1.5rem 5rem 2rem !important; }
  .page-break {
    padding-top: 5rem;
    page-break-before: always; } }
/* Buttons */
/* Inputs */
/* Timeline custom component */
.timeline {
  border-left: 2px solid #E6E9ED;
  padding: 1rem 0; }

.timeline-card {
  position: relative;
  margin-left: 31px;
  border-left: 2px solid;
  margin-bottom: 2rem; }

.timeline-card:last-child {
  margin-bottom: 1rem; }

.timeline-card:before {
  content: '';
  display: inline-block;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  top: 20px;
  left: -41px;
  border: 2px solid;
  z-index: 2; }

.timeline-card:after {
  content: '';
  display: inline-block;
  position: absolute;
  background-color: currentColor;
  width: 29px;
  height: 2px;
  top: 25px;
  left: -29px;
  z-index: 1; }

.timeline-card-primary {
  border-left-color: #4A89DC; }

.timeline-card-primary:before {
  border-color: #4A89DC; }

.timeline-card-primary:after {
  background-color: #4A89DC; }

.timeline-card-success {
  border-left-color: #37BC9B; }

.timeline-card-success:before {
  border-color: #37BC9B; }

.timeline-card-success:after {
  background-color: #37BC9B; }

html {
  scroll-behavior: smooth; }

.site-title {
  font-size: 1.25rem;
  line-height: 2.5rem; }

.nav-link {
  padding: 0;
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: rgba(0, 0, 0, 0.5); }

.nav-link:hover,
.nav-link:focus,
.active .nav-link {
  color: rgba(0, 0, 0, 0.8); }

.nav-item + .nav-item {
  margin-left: 1rem; }

.cover {
  border-radius: 10px; }

.cover-bg {
  background-color: #4A89DC;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.12'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  border-radius: 10px 10px 0 0; }
.missing-avatar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: #3d4148;
  color: #ffffff;
  font-size: 80px;
  border-radius: 50%;
  font-weight: 800;
}
.avatar {
  max-width: 216px;
  max-height: 216px;
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.avatar img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

footer a:not(.nav-link) {
  color: inherit;
  border-bottom: 1px dashed;
  text-decoration: none;
  cursor: pointer; }

@media (min-width: 48em) {
  .site-title {
    float: left; }
  .site-nav {
    float: right; }
  .avatar {
    margin-bottom: -80px;
    margin-left: 0; } }

@media print {
  body {
    background-color: #fff; }
  .container {
    width: auto;
    max-width: 100%;
    padding: 0; }
  .cover, .cover-bg {
    border-radius: 0; }
  .cover.shadow-lg {
    box-shadow: none !important; }
  .cover-bg {
    padding: 5rem !important;
    padding-bottom: 10px !important; }
  .avatar {
    margin-top: -10px; }  
  .about-section {
    padding: 6.5rem 5rem 2rem !important; }
  .skills-section,
  .work-experience-section,
  .education-section,
  .contant-section {
    padding: 1.5rem 5rem 2rem !important; }
  .page-break {
    padding-top: 5rem;
    page-break-before: always; }
     
  
  }
